import { NameAndIdType, SecurityManagerType, SurveyViewOfControlType } from "./types";

export const DEFAULT_LOCALE = 'en-US';

export const HOMEPAGE_MESSAGE_BUNDLE_NAME = 'pages.homepage';

export const BETA_API_BASE_URL = 'https://y3n8hp805j.execute-api.us-east-1.amazonaws.com';
export const PROD_API_BASE_URL = 'https://awl1s5h948.execute-api.us-east-1.amazonaws.com';

export const emptyCarverRef = {
    criticality: 0,
    accessibility: 0,
    recoverability: 0,
    vulnerability: 0,
    effect: 0,
    recognizibility: 0
}

export const emptyLocation = {
    entityId: "",
    isActive: 1,
    confidentialOrRestricted: 0,
    consequenceSet: "",
    site: "",
    type: "Location",
    region: "",
    country: "",
    RSM: "",
    ASM: "",
    PSEC: "",
    dateCreated: "",
    lastModified: "",
    protectiveGroup: "",
    aor: "",
    state_province: "",
    city: "",
    address: "",
    locationType: "",
    contextStatement: "",
    protectiveRequirements: "",
    site_risk_avg: 0,
    i90: 0, //num of incidents in the last 90 days
    avg_effect: 0,
    carver_assessment: 0,
    carver_criticality: 0,
    carver_accessibility: 0,
    carver_recoverability: 0,
    carver_vulnerability: 0,
    carver_effect: 0,
    carver_recognizability: 0,
    survey_completed: 0,
    survey_completed_date: "",
    survey_partially_completed: 0,
    survey_partially_completed_date: ""
}

export const emptyIncident = {
    analyst_notes: "",
    bookmarked: 0,
    case_priority: 0,
    city: "",
    country: "",
    date_reported: "",
    display_id: "",
    duplicate: 0,
    id: "",
    location: "",
    needs_review: 0,
    notes: "",
    reference: "",
    risk_full_name: "",
    region: "",
    status: "",
    source: "",
    source_type: "",
    state_province: "",
    sub_risk_full_name: "",
    sub_type: "",
    threat_originator: "",
    title: "",
    triage_status: "",
    type: "",
    urn: ""
}

export const IncidentSources = ["Internal", "External"];
export const IncidentStatuses = ["Actual (Occurred)", "Prevented", "Potential"];
export const IncidentSourceTypes = ["Incident", "Intel", "Alert"];

export const EmptySubRisk = {
    acs_risk_dim_sk: 0, //this is the actual ID in the DB
    risk_id: 0,
    sub_risk_name: "",
    sub_risk_full_name: "",
    active_flag: 0,
    edw_created_date: "",
    last_updated: "",
    sub_risk_id: "", //a, b, etc...
    description: "",
    reportable: 0,
    specific_context: ""
}

export const EmptyRisk = {
    risk_number: 0,
    risk_name: "",
    risk_name_full: "",
    active_flag: 0,
    edw_created_date: "",
    last_updated: "",
    description: "",
}

export const EmptyIncidentRisk = {
    subRiskID: "",
    incidentID: "",
    isPrimary: 0
};

export const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page',
};

export const ProtectiveGroups = ["Amazon Corporate Security", "Prototype Security", "Amazon Studios", "Workplace Incident Management"];

export const EmptyControl = {
    id: 0,
    category_type_id: 0,
    category_id: 1,
    control_name: "",
    deter: 0,
    detect: 0,
    delay: 0,
    respond: 0,
    recover: 0,
    owner_id: 1,
    category_name: "",
    owner_name: "",
    generalDescription: "",
    status: "",
    conditional_use: 0,
    hi_impact: 0
}

export const EmptySubControl = {
    controlID: "",
    id: "",
    name: "",
    isActive: "0"
};

export const EmptyControlCategory = {
    name: "",
    id: 0
}

export const EmptyControlOwner: NameAndIdType = { name: "", id: 0 };

export const EmptySurveyViewOfControl: SurveyViewOfControlType = {
    category_type_id: 0, //lighting, etc.
    category_id: 0, //Procedural, People, etc
    category_name: "",
    control_id: 0,
    control_name: "",
    conditional_use: 0,
    effective: 0, //a number from 0-4
    generalDescription: "",
    hi_impact: 0,
    isFirstOfCategory: false,
    is_utilized: 0,
    owner: "",
}

export enum IsUtilizedValues {
    NOT_SET = -1,
    N_A = 3,
    NO = 0,
    PARTIAL = 2,
    YES = 1
}

export const IsUtilizedOptions = [
    { label: "Choose", value: "-1" },
    { label: "N/A", value: "3" },
    { label: "No", value: "0" },
    { label: "Partial", value: "2" },
    { label: "Yes", value: "1" }
]

export const EffectivenessOptions = [
    { label: "choose effectiveness", value: "-1" },
    { label: "0 - Not Present", value: "0" },
    { label: "1 - Not Effective", value: "1" },
    { label: "2 - Partially Effective", value: "2" },
    { label: "3 - Mostly Effective", value: "3" },
    { label: "4 - Effective", value: "4" }
]

export enum EffectivenessDefinitions {
    "Not Present",
    "Not Effective",
    "Partially Effective",
    "Mostly Effective",
    "Effective"
}

export enum ConsequenceIds {
    assets = 1,
    safety = 2,
    business_ops = 3,
    security_ops = 4,
    reputation = 5,
    regulatory = 6,
    legal = 7,
    financial = 8,
    data = 9
}

export const ConsequenceFriendlyNames = {
    assets: "Assets",
    safety: "Safety",
    business_ops: "Business Ops",
    security_ops: "Security Ops",
    reputation: "Reputation",
    regulatory: "Regulatory",
    legal: "Legal",
    financial: "Financial",
    data: "Data"
}


export const EmptyConsequenceAssessmentTableRow = {
    subRiskId: 0,
    subRiskName: "",
    assets: 0,
    safety: 0,
    business_ops: 0,
    security_ops: 0,
    reputation: 0,
    regulatory: 0,
    legal: 0,
    financial: 0,
    data: 0
}

export enum NullableBoolean {
    false = -1,
    unset = 0,
    true = 1
}

export const EmptyManager: SecurityManagerType = {
    id: 0,
    region: "",
    aor: "",
    full_name: "",
    roll_up: "",
    alias: "",
    role: ""
}

export const RegionOptions = [
    { label: "Select region", value: "" },
    { label: "APAC", value: "APAC" },
    { label: "EMEA", value: "EMEA" },
    { label: "AMER", value: "AMER" },
];

export const ManagerRoleOptions = [
    { label: "Select role", value: "" },
    { label: "RSM", value: "RSM" },
    { label: "ASM", value: "ASM" },
];

export const NOTES_MINIMUM_LENGTH = 5;

export const INCIDENT_TYPES = [
    "Missing Property",
    "Behavioral Conduct",
    "Violence",
    "Property Damage",
    "Access Violation",
    "Environmental, Health & Safety",
    "Workplace Incident",
    "Theft",
    "Bomb Threat",
    "Workplace Security Support",
    "Burglary",
    "Policy Violation",
    "Trespass",
    "Harassment",
    "Suspicious Activity",
    "Intrusion / Forced Entry",
    "Information Request",
    "Customer Incident",
    "Threat",
    "Loss / Theft",
    "Discrimination",
    "Vehicle-related Incident",
    "Las Vegas Alarm",
    "Misuse of Company Assets",
    "BOLO Sighting",
    "Property Damage / Vandalism",
    "Welfare Concern",
    "Workplace Violence",
    "Other Facility Request",
    "Fraud",
    "Medical",
    "Odor Investigation",
    "Missing Person",
    "Other Alarm",
    "Criminal Activity",
    "Civil Unrest",
    "Distressed Traveler",
    "Robbery",
    "Protest / Demonstration",
    "Domestic Violence Threat",
    "Animal Complaint",
    "Kidnapping / Ransom",
    "Extortion",
    "Other Customer Service",
    "Internal Protection Investigation",
    "Conflict of Interest",
    "Other Incident",
    "Customer Inappropriate Conduct",
    "Suspicious Package",
    "Hit & Run",
    "Amazon Studios Unsanctioned Disclosure",
    "Legal / Regulatory Response",
    "Legal or Regulatory Response",
    "Falsification of Records",
    "Theft / Stolen Property",
    "Vandalism",
    "TBD",
    "Assault",
    "Corruption",
    "Espionage",
    "False Fire Alarm",
    "Fire / Smoke",
    "Customer Racist Contact",
    "Duplicate / Erroneous Report",
    "Duress / SAR / Lockdown Alarm",
    "Kidnapping",
    "Bribery",
    "Customer Sexually Explicit",
    "Security / Escort Support Request",
    "Facilities / Utility",
    "Inappropriate Conduct",
    "Environment / Weather",
    "Alarm",
    "Threat / Aggressive Behavior",
    "Animal Incident",
    "Transportation Accident",
    "Suspicious Object",
    "Intrusion",
    "Security Device Issue",
    "Well-Being Concern",
    "Demonstration",
    "Armed Aggressor",
    "Missing Person (not Welfare Check)",
];
import React, { useContext } from "react";
import { Box, Button, ColumnLayout, Container, Header, Pagination, Popover, Table, TextFilter } from "@amzn/awsui-components-react/polaris";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { ModalContext } from "../useModal";

import { HealthCheckRowType } from "src/types";
import EmptyState from "../EmptyState";
import { paginationLabels } from "../../constants";
import { DefinitionsContext } from "../DefinitionsProvider";
import { RiskContext } from "../RiskProvider";
import EffectivenessDefinitionsLayout from "../EffectivenessDefinitionsLayout";


const HealthCheck = () => {
  const { healthCheckResults, selectedEntity } = useContext(ModalContext);
  const { getFirstSubRiskId, getFullCategoryName } = useContext(RiskContext);

  function getMatchesCountText(count: number | undefined) {
    return count === 1 ? `1 match` : `${count} matches`;
  }

  const { RiskRatingDefinitions } = useContext(DefinitionsContext);

  const getRiskColor = (rating: number): string => {
    if (rating > 19) {
      return "red";
    }
    if (rating > 10) {
      return "orange";
    }
    if (rating > 4) {
      return "green";
    }
    return "#B6BEC9";
  }

  const {
    items: itemsFromUseCollection,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(healthCheckResults, {
    filtering: {
      empty: (
        <EmptyState
          title="No Items Found"
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: 25 },
    sorting: {},
    selection: {},
  });

  const getCellStyle = (risk: HealthCheckRowType) => {
    if (risk.risk_name === "") return { display: "none" };
    const firstLetter = getFirstSubRiskId(parseInt(risk.risk_name?.[0]))

    if (risk.risk_name[1]?.toLocaleLowerCase() === firstLetter) {
      return { display: "inline" };
    } else {
      return { display: "none" };
    }
  };

  return (<Table
    {...collectionProps}
    columnDefinitions={[
      {
        id: "risk-category",
        header: "Risk category",
        cell: (e: HealthCheckRowType) => {
          return (<span style={getCellStyle(e)}>{getFullCategoryName(e.risk_category_name)}</span>);
        },
        isRowHeader: true,
        width: 330,
      },
      {
        id: "risk",
        header: "Risk",
        cell: (e: HealthCheckRowType) => e.risk_name,
        width: 400,
      },
      {
        id: "controls-number",
        header: <><div>Number of controls</div><div>(active/available)</div></>,
        cell: (e: HealthCheckRowType) => e.controls_count,
        width: 225,
      },
      {
        id: "percentage",
        header: "Control utilization (%)",
        cell: (e: HealthCheckRowType) => `${Math.round(e.coverage_pct * 100)}%`,
      },
      {
        id: "effectiveness",
        header: <Popover
          fixedWidth
          header="Control Effectiveness Definitions"
          size="large"
          position="bottom"
          content={
            <EffectivenessDefinitionsLayout />
          }
        >
          <strong>Average effectiveness</strong>
        </Popover>,
        cell: (e: HealthCheckRowType) => e.effectiveness,
        width: 175,
      },
      {
        id: "control-likelihood",
        header: <Popover
          fixedWidth
          header="Control-based Likelihood Definitions"
          size="large"
          content={
            <ColumnLayout>
              <Container><Box variant="awsui-key-label">Rating</Box><Box variant="awsui-key-label">Definition</Box></Container>
              <Container>
                <Box>1 - <b>Rare</b></Box>
                <Box>All of the controls are strong, with no control gaps. The strength of this control environment means it is likely that if the risk eventuates it will be caused by external factors beyond the control of the organisation.</Box></Container>
              <Container>
                <Box>2 - <b>Unlikely</b></Box>
                <Box>The majority of the controls are strong, with few control gaps. The strength of this control environment means it is likely that if the risk eventuates it will be caused by external factors not known to the organisation.</Box></Container>
              <Container>
                <Box>3 - <b>Possible</b></Box>
                <Box>Some controls associated with the risk are weak and/or missing. However, without control improvement there is still no certainty that the risk will eventuate.</Box></Container>
              <Container>
                <Box>4 - <b>Likely</b></Box>
                <Box>The majority of the controls associated with the risk are weak and/or missing. Without control improvement it is more likely than not that the risk will eventuate.</Box></Container>
              <Container>
                <Box>5 - <b>Almost Certain</b></Box>
                <Box>All the controls associated with the risk are extremely weak and/or missing. Without control improvement there is almost no doubt that the risk will eventuate.</Box></Container>
            </ColumnLayout>
          }
        >
          <strong>CBL</strong>
        </Popover>,
        cell: (e: HealthCheckRowType) => e.cbr_likelihood,
      },
      {
        id: "incident-based-likelihood",
        header: <Popover
          fixedWidth
          header="Incident-based Likelihood Definitions"
          size="large"
          content={
            <ColumnLayout>
              <Container><Box variant="awsui-key-label">Rating</Box><Box variant="awsui-key-label">Definition</Box></Container>
              <Container>
                <Box>1 - <b>Rare</b></Box>
                <Box>All of the controls are strong, with no control gaps. The strength of this control environment means it is likely that if the risk eventuates it will be caused by external factors beyond the control of the organisation.</Box></Container>
              <Container>
                <Box>2 - <b>Unlikely</b></Box>
                <Box>The majority of the controls are strong, with few control gaps. The strength of this control environment means it is likely that if the risk eventuates it will be caused by external factors not known to the organisation.</Box></Container>
              <Container>
                <Box>3 - <b>Possible</b></Box>
                <Box>Some controls associated with the risk are weak and/or missing. However, without control improvement there is still no certainty that the risk will eventuate.</Box></Container>
              <Container>
                <Box>4 - <b>Likely</b></Box>
                <Box>The majority of the controls associated with the risk are weak and/or missing. Without control improvement it is more likely than not that the risk will eventuate.</Box></Container>
              <Container>
                <Box>5 - <b>Almost Certain</b></Box>
                <Box>All the controls associated with the risk are extremely weak and/or missing. Without control improvement there is almost no doubt that the risk will eventuate.</Box></Container>
            </ColumnLayout>
          }
        >
          <strong>
            IBL
          </strong>
        </Popover>,
        cell: (e: HealthCheckRowType) => e.ib_likelihood,
      },
      {
        id: "likelihood-rating",
        header: "Likelihood rating",
        cell: (e: HealthCheckRowType) => (<span style={{ color: getRiskColor(e.overall_likelihood) }}>{Math.round(e.overall_likelihood)} - {RiskRatingDefinitions[Math.round(e.overall_likelihood)]}</span>)
      },
    ]}
    enableKeyboardNavigation
    items={itemsFromUseCollection}
    loadingText="Loading healthcheck data"
    wrapLines
    trackBy="risk_name"
    filter={
      <TextFilter
        {...filterProps}
        countText={getMatchesCountText(filteredItemsCount)}
        filteringPlaceholder="Find a risk or risk category"
        filteringAriaLabel="Filter risks"
      />
    }
    header={
      <Header>
        {selectedEntity.site} health check
      </Header>
    }
    pagination={
      <Pagination {...paginationProps} ariaLabels={paginationLabels} />
    }
  />);
}

export default HealthCheck;
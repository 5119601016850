import React, {useContext, useState} from "react";
import { Tabs } from "@amzn/awsui-components-react/polaris";
import SiteLandingPage from "./SiteLandingPage/SiteLandingPage";
import ControlsRegister from "./ControlsRegister";
import IncidentData from "./IncidentData";
import HealthCheck from "./HealthCheck";
import RiskRegister from "./RiskRegister";
import StrategicControlManagementPlan from "./SCMP";
import {SiteContext} from "./useNewTabbedView";

const SiteContainer = () => {
  const {setInTabbedView} = useContext(SiteContext);

  const [activeTabId, setActiveTabId] = useState("site-landing-page");
  return (<Tabs
  activeTabId={activeTabId}
  onChange={(evt) => {
    setActiveTabId(evt.detail.activeTabId)
    if(evt.detail.activeTabId === "home") setInTabbedView(false);
  }}
    tabs={[
      {
        label: "Home",
        id: "home",
        content: "",
      },
      {
        label: "Site homepage",
        id: "site-landing-page",
        content: <SiteLandingPage />,
  
      },
      {
        label: "Health check",
        id: "health-check",
        content: <HealthCheck />,
      },
      {
        label: "Risk register",
        id: "risk-register",
        content: <RiskRegister />,
      },
      {
        label: "Controls register",
        id: "controls-register",
        content: <ControlsRegister />
      },
      {
        label: "Security controls management plan",
        id: "scmp",
        content: <StrategicControlManagementPlan />
      },
      {
        label: "Incident data",
        id: "incident-data",
        content: <IncidentData />,
      },
      {
        label: "Assurance information",
        id: "Assurance information",
        content: <div className="site-tab-content">All defect statuses to be listed here</div>,
      },
      {
        label: "SPT & GRIS CSRT",
        id: "spt-and-gris",
        content: <div className="site-tab-content">Site prioritization tool and GRIS corporate site risk tool information displayed here</div>,
      },
      {
        label: "Activity log",
        id: "activity-log",
        content: <div className="site-tab-content">Site Activity Log + Timeline</div>,
      }
    ]}
  />)
}

export default SiteContainer;
import React, { useContext, useEffect, useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  ColumnLayout,
  Container,
  Header,
  Select,
  Input,
  SelectProps,
  RadioGroup,
  FormField,
} from "@amzn/awsui-components-react";
import { ModalContext } from "./useModal";
import { EntityAndManagerContext } from "./EntityAndManagerProvider";
import { FlashMessageContext } from "./useFlashMessages";
import { ProtectiveGroups, emptyLocation, RegionOptions } from "../constants";
import { EntityType, SecurityManagerType } from "src/types";
import Select0to5 from "./Select0to5";


interface AddSiteModalProps {
  entity: EntityType
}
const AddSiteModal = (props: AddSiteModalProps) => {
  const { entity } = props;
  const { isShowing, hideModals } = React.useContext(
    ModalContext
  );
  const { setShowSuccess, setFlashMessage } = useContext(FlashMessageContext);
  const { postOrPutChanges, aors, selectedRegion, setSelectedRegion, countries, selectedAOR, setSelectedAOR, managers, refreshEntitiesAndManagers } = React.useContext(EntityAndManagerContext);

  const [selectedTypeOption, setSelectedTypeOption] = useState<SelectProps.Option>({});
  const [selectedRegionOption, setRegionOption] = useState<SelectProps.Option>({});
  const [selectedProtectiveGroupOption, setSelectedProtectiveGroupOption] = useState<SelectProps.Option>({});
  const [selectedRSMOption, setSelectedRSMOption] = useState<SelectProps.Option>({});
  const [selectedASMOption, setSelectedASMOption] = useState<SelectProps.Option>({});
  const [selectedAorOption, setSelectedAorOption] = useState<SelectProps.Option>({});
  const [selectedCountryOption, setSelectedCountryOption] = useState<SelectProps.Option>({});
  const [selectedLocationTypeOption, setSelectedLocationTypeOption] = useState<SelectProps.Option>({});

  const [selectedCarverCriticality, setSelectedCarverCriticality] = useState(0);
  const [selectedCarverAccessibility, setSelectedCarverAccessibility] = useState(0);
  const [selectedCarverRecoverability, setSelectedCarverRecoverability] = useState(0);
  const [selectedCarverVulnerability, setSelectedCarverVulnerability] = useState(0);
  const [selectedCarverEffect, setSelectedCarverEffect] = useState(0);
  const [selectedCarverRecognizability, setSelectedCarverRecognizability] = useState(0);
  const [totalCarverScore, setTotalCarverScore] = useState("-")

  const [isDirty, setIsDirty] = useState(false);
  const protectiveGroupOptions = ProtectiveGroups.map((pg) => ({ label: pg, value: pg }));
  protectiveGroupOptions.unshift({label: "Select protective group", value: ""});
  const ASMOptions = managers.map((x: SecurityManagerType) => ({ label: x.full_name, value: x.alias }));
  ASMOptions.unshift({label: "Select ASM", value: ""});

  const RSMOptions = managers.map((x: SecurityManagerType) => ({ label: x.full_name, value: x.alias }));
  RSMOptions.unshift({label: "Select RSM", value: ""});
  let AOROptions = aors.map((x) => ({ label: x, value: x }));
  AOROptions.unshift({ label: "Select AOR", value: "" });
  let countryOptions = countries.map((x) => ({ label: x, value: x }));
  countryOptions.unshift({ label: "Select country", value: "" });

  const typeOptions = [
    { label: "Select type", value: "" },
    { label: "Location", value: "location" },
    { label: "Event / Activity", value: "event-activity", disabled: true },
    { label: "Asset / Object", value: "asset-object", disabled: true },
    { label: "Organization", value: "org", disabled: true },
    { label: "Person", value: "person", disabled: true },
  ];


  const locationTypeOptions = [
    { label: "Select location type", value: "" },
    { label: "Corporate Location", value: "Corporate" },
    { label: "Subsite Location", value: "Subsite" },
    { label: "Offsite Location", value: "Offsite" },
  ];

  const [isActive, setIsActive] = useState(1);
  const [isConfidentialOrRestricted, setIsConfidentialOrRestricted] = useState(0);
  const [carverAssessmentNeeded, setCarverAssessmentNeeded] = useState(0);
  const [site, setSite] = useState("");
  const [PSEC, setPSEC] = useState("");
  const [state_province, setState_province] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [contextStatement, setContextStatement] = useState("");
  const [protectiveRequirements, setProtectiveRequirements] = useState("");
  const [consequenceSet, setConsequenceSet] = useState("");
  const heading = "New site";

  const reset = () => {
    setIsActive(1);
    setIsConfidentialOrRestricted(0);
    setCarverAssessmentNeeded(0);
    setSite('');
    setPSEC('');
    setState_province('');
    setCity('');
    setAddress('');
    setRegionOption(RegionOptions[0]);
    setSelectedCountryOption(countryOptions[0]);
    setSelectedAorOption(AOROptions[0]);
    setSelectedASMOption(ASMOptions[0]);
    setSelectedRSMOption(RSMOptions[0]);
    setSelectedTypeOption(typeOptions[0]);
    setSelectedProtectiveGroupOption(protectiveGroupOptions[0]);
    setSelectedLocationTypeOption(locationTypeOptions[0]);
  }

  useEffect(() => {
    if (isDirty) {
      setSelectedAorOption(AOROptions[0]);
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (isDirty) {
      setSelectedCountryOption(countryOptions[0]);
    }
  }, [selectedAOR]);

  useEffect(() => {
    const total = selectedCarverCriticality + selectedCarverAccessibility + selectedCarverRecoverability
      + selectedCarverVulnerability + selectedCarverEffect + selectedCarverRecognizability;
    setTotalCarverScore(total !== 0 ? total.toString() : "-");
  }, [selectedCarverCriticality,
    selectedCarverAccessibility,
    selectedCarverRecoverability,
    selectedCarverVulnerability,
    selectedCarverEffect,
    selectedCarverRecognizability]);

  const allFieldsAreValid = () => {
    return site.length > 0
      && selectedTypeOption !== undefined
      && selectedProtectiveGroupOption !== undefined
      && selectedRegionOption.value !== ""
      && selectedAorOption.value !== ""
      && selectedCountryOption.value !== ""
      && state_province.length > 0
      && city.length > 0
      && address.length > 0
      && selectedRSMOption !== undefined
      && selectedASMOption !== undefined
      && PSEC.length > 0
      && selectedLocationTypeOption !== undefined;
  }

  return (
    <Modal
      onDismiss={() => hideModals()}
      visible={isShowing("addSite")}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={(_event) => {
              setIsDirty(false);
              setSelectedRegion(emptyLocation.region);
              setSelectedAOR(emptyLocation.aor);
              setSelectedCountryOption(countryOptions[0]);
              hideModals();
            }}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={async (_event) => {
                if (allFieldsAreValid()) {
                  const successful = await postOrPutChanges({
                    entityId: entity.entityId,
                    aor: selectedAorOption.value!,
                    consequenceSet,
                    isActive,
                    confidentialOrRestricted: isConfidentialOrRestricted,
                    site,
                    type: selectedTypeOption.value!,
                    region: selectedRegionOption.value!,
                    country: selectedCountryOption.value!,
                    RSM: selectedRSMOption.value!,
                    ASM: selectedASMOption.value!,
                    PSEC,
                    protectiveGroup: selectedProtectiveGroupOption.value!,
                    dateCreated: new Date().toISOString(),
                    lastModified: new Date().toISOString(),
                    state_province,
                    city,
                    address,
                    locationType: selectedLocationTypeOption.value!,
                    contextStatement,
                    protectiveRequirements,
                    site_risk_avg: 0,//these next three are ignored in the API handler
                    i90: 0, //num of incidents in the last 90 days
                    avg_effect: 0,
                    carver_assessment: carverAssessmentNeeded,
                    carver_accessibility: selectedCarverAccessibility,
                    carver_criticality: selectedCarverCriticality,
                    carver_effect: selectedCarverEffect,
                    carver_recognizability: selectedCarverRecognizability,
                    carver_vulnerability: selectedCarverVulnerability,
                    carver_recoverability: selectedCarverRecoverability,
                    survey_completed: 0,
                    survey_completed_date: "",
                    survey_partially_completed: 0,
                    survey_partially_completed_date: ""
                  });
                 
                  if(successful){
                    setIsDirty(false);
                    refreshEntitiesAndManagers();
                    setFlashMessage(`${site} added`);
                    setShowSuccess(true);
                    reset();
                  }
                }
              }}
            >
              Save
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Container
        header={
          <Header
            variant="h2"
          >
            {heading}
          </Header>
        }
      >
        <div className="body">
          <div className="modal-body">
            <Header variant="h1">Site: {site}</Header>
            <div className="admin-section">
              <div id="entity-status">
                Status:
                <fieldset>
                  <RadioGroup
                    onChange={({ detail }) => {
                      setIsDirty(true);
                      setIsActive(detail.value === "1" ? 1 : 0)
                    }}
                    value={"" + isActive}
                    items={[
                      { value: "1", label: "Active" },
                      { value: "0", label: "Inactive" },
                    ]}
                  />
                </fieldset>
              </div>
            </div>
            {/* Site == name */}
            <FormField
              label="Name: "
            >
              <Input
                type="text"
                name="site"
                onChange={(e) => {
                  setIsDirty(true);
                  setSite(e.detail.value)
                }}
                value={site}
              />
            </FormField>
            <div className="entity-info">
              <FormField label="Entity Type:">
                <Select
                  selectedOption={selectedTypeOption}
                  placeholder="Select type"
                  onChange={({ detail }) => {
                    setIsDirty(true);
                    setSelectedTypeOption(detail.selectedOption);
                  }}
                  options={typeOptions}
                />
              </FormField>
            </div>

            <div className="entity-info">
              <FormField label="Protective Group:">
                <Select
                  selectedOption={selectedProtectiveGroupOption}
                  placeholder="Select Protective group"
                  onChange={({ detail }) => {
                    setIsDirty(true);
                    setSelectedProtectiveGroupOption(detail.selectedOption);
                  }}
                  options={protectiveGroupOptions}
                />
              </FormField>
            </div>

            <div>
              Confidential or restricted view:
              <fieldset>
                <RadioGroup
                  onChange={({ detail }) => {
                    setIsDirty(true);
                    setIsConfidentialOrRestricted(detail.value === "1" ? 1 : 0)
                  }}
                  value={"" + isConfidentialOrRestricted}
                  items={[
                    { value: "1", label: "Yes" },
                    { value: "0", label: "No" },
                  ]}
                />
              </fieldset>
            </div>

            <div>
              Carver Assessment:
              <fieldset>
                <RadioGroup
                  onChange={({ detail }) => {
                    setIsDirty(true);
                    setCarverAssessmentNeeded(detail.value === "1" ? 1 : 0)
                  }}
                  value={"" + carverAssessmentNeeded}
                  items={[
                    { value: "1", label: "Yes" },
                    { value: "0", label: "No" },
                  ]}
                />
              </fieldset>
            </div>

            {carverAssessmentNeeded === 1 && <ColumnLayout columns={2}

            >
              <Box>
                <FormField label="Criticality:">
                  <Select0to5
                    value={selectedCarverCriticality}
                    onChange={(newVal) => {
                      setSelectedCarverCriticality(parseInt(newVal));
                    }}
                  />

                </FormField>
                <FormField label="Accessibility:">
                  <Select0to5
                    value={selectedCarverAccessibility}
                    onChange={(newVal) => {
                      setSelectedCarverAccessibility(parseInt(newVal));
                    }}
                  />
                </FormField>
                <FormField label="Recoverability:">
                  <Select0to5
                    value={selectedCarverRecoverability}
                    onChange={(newVal) => {
                      setSelectedCarverRecoverability(parseInt(newVal));
                    }}
                  />
                </FormField>
                <FormField label="Vulnerability:">
                  <Select0to5
                    value={selectedCarverVulnerability}
                    onChange={(newVal) => {
                      setSelectedCarverVulnerability(parseInt(newVal));
                    }}
                  />
                </FormField>
                <FormField label="Effect:">
                  <Select0to5
                    value={selectedCarverEffect}
                    onChange={(newVal) => {
                      setSelectedCarverEffect(parseInt(newVal));
                    }}
                  />
                </FormField>

                <FormField label="Recognizability:">
                  <Select0to5
                    value={selectedCarverRecognizability}
                    onChange={(newVal) => {
                      setSelectedCarverRecognizability(parseInt(newVal));
                    }}
                  />
                </FormField>
              </Box>
              <Box><h2>Carver Score: {totalCarverScore} </h2></Box>
            </ColumnLayout>



            }

            <div className="entity-additional-info">
              {/* Location Entity Additional Fields form, should connect to the Entity input item to display, when selected above should set "active" class */}
              <div className="additional-fields active" id="location-entity">
                <div id="location-info">
                  <h3 className="title">Location</h3>

                  <span className="title">Region</span>
                  <div className="filter-style" id="asset-filter-dropdown">
                    <Select
                      selectedOption={selectedRegionOption}
                      placeholder="Select region"
                      onChange={({ detail }) => {
                        setIsDirty(true)
                        setRegionOption(detail.selectedOption)
                        setSelectedRegion(detail.selectedOption.value!)

                      }}
                      options={RegionOptions}
                    />

                  </div>

                  <span className="title">AOR</span>
                  <div className="filter-style" id="asset-filter-dropdown">
                    <Select
                      selectedOption={selectedAorOption}
                      placeholder="Select AOR"
                      onChange={({ detail }) => {
                        setIsDirty(true)
                        setSelectedAorOption(detail.selectedOption);
                        setSelectedAOR(detail.selectedOption.value!)
                      }}
                      options={AOROptions}
                    />

                  </div>

                  <span className="title">Country</span>
                  <div className="filter-style" id="asset-filter-dropdown">
                    <Select
                      selectedOption={selectedCountryOption}
                      placeholder="Select Country"
                      onChange={({ detail }) => {
                        setIsDirty(true);
                        setSelectedCountryOption(detail.selectedOption);
                      }

                      }
                      options={countryOptions}
                    />
                  </div>
                </div>
              </div>

              <div>
                <FormField
                  label="State or Province: "
                >
                  <Input
                    type="text"
                    onChange={(e) => {
                      setIsDirty(true);
                      setState_province(e.detail.value);
                    }}
                    value={state_province}
                  />
                </FormField>
              </div>

              <div>
                <FormField
                  label="City:"
                >
                  <Input
                    type="text"
                    onChange={(e) => {
                      setIsDirty(true);
                      setCity(e.detail.value);
                    }}
                    value={city}
                  />
                </FormField>
              </div>

              <div>
                <FormField
                  label="Address: "
                >
                  <Input
                    type="text"
                    onChange={(e) => {
                      setIsDirty(true);
                      setAddress(e.detail.value)
                    }}
                    value={address}
                  />
                </FormField>
              </div>

              <span className="title">RSM</span>

              <div className="filter-style" id="asset-filter-dropdown">
                <Select
                  selectedOption={selectedRSMOption}
                  placeholder="Select RSM"
                  onChange={({ detail }) => {
                    setIsDirty(true)
                    setSelectedRSMOption(detail.selectedOption);
                  }}
                  options={RSMOptions}
                />
              </div>

              <span className="title">ASM</span>
              <div className="filter-style" id="asset-filter-dropdown">
                <Select
                  selectedOption={selectedASMOption}
                  placeholder="Select ASM"
                  onChange={({ detail }) => {
                    setIsDirty(true);
                    setSelectedASMOption(detail.selectedOption)
                  }

                  }
                  options={ASMOptions}
                />
              </div>

              <FormField
                label="PSEC"
              >
                <Input
                  type="text"
                  name="PSEC"
                  value={PSEC}
                  onChange={(e) => {
                    setIsDirty(true);
                    setPSEC(e.detail.value);
                  }}
                />
              </FormField>

              <FormField label="Location Type:">
                <Select
                  selectedOption={selectedLocationTypeOption}
                  placeholder="Select location type"
                  onChange={({ detail }) => {
                    setIsDirty(true);
                    setSelectedLocationTypeOption(detail.selectedOption);
                  }}
                  options={locationTypeOptions}
                />
              </FormField>
            </div>
          </div>
        </div>

      </Container>
    </Modal>
  );
};

export default AddSiteModal;
import { createContext, Dispatch, useContext, useEffect, useReducer, useState } from "react";
import { ControlDataContext } from "./ControlsProvider";
import { SurveyViewOfControlType, ReducerAction, ControlType } from "src/types";
import { EmptySurveyViewOfControl, IsUtilizedValues } from "src/constants";
import { ModalContext } from "./useModal";
import { RoleContext } from "./RoleProvider";
import { getBaseUrl, prettyDateTime } from "src/utils";

export type SurveyContextType = {
  surveyQuestions: SurveyViewOfControlType[];
  lastUpdated: string;
}



export const SurveyContext = createContext<{
  state: SurveyContextType, dispatch: Dispatch<ReducerAction>,
  postSurveyAnswers: () => void,
  postPartialSurvey: () => void,
  copyFromExistingSurvey: (entityId: string) => void,
  refetchSurveyForSelectedSite: () => void
}>(
  {
    state: {
      surveyQuestions: [EmptySurveyViewOfControl],
      lastUpdated: ""
    },
    dispatch: () => null,
    postSurveyAnswers: () => null,
    postPartialSurvey: () => null,
    copyFromExistingSurvey: (entityId: string) => null,
    refetchSurveyForSelectedSite: () => null
  }
);

export const SurveyContextProvider = (props: { children: JSX.Element }) => {
  const { children } = props;
  const { token, loggedInUser } = useContext(RoleContext);
  const { selectedEntity } = useContext(ModalContext);
  const { allControls, controlOwners, controlCategories } = useContext(ControlDataContext);
  //whenever this changes, I refetch the Surveys and subSurveys
  const [fetchCounter, setFetchCounter] = useState(0);
  const [state, dispatch] = useReducer(Reducer, {
    surveyQuestions: [EmptySurveyViewOfControl],
    lastUpdated: ""
  });

  function Reducer(state: SurveyContextType, action: ReducerAction) {
    switch (action.Type) {
      case "UPDATE_SURVEY_QUESTIONS": {
        const surveyQuestions = action.Cargo.Surveys;
        //Decorate the surveyResponses with isFirstOfCategory to hide the categories on all but the first control 
        controlCategories.forEach((cat) => {
          const firstOfThisCatgory = surveyQuestions.filter((existingControl: SurveyViewOfControlType) => existingControl.category_type_id === cat.id)?.[0];
          firstOfThisCatgory.isFirstOfCategory = true;
        })
        return {
          surveyQuestions,
          lastUpdated: ""
        };
      }
      case "UPDATE_ENTITY_CONTROL_IS_UTILIZED": {
        const indexToRemove = state.surveyQuestions.findIndex((q) => q.control_id === action.Cargo.controlId);

        const first = state.surveyQuestions.slice(0, indexToRemove);
        const third = state.surveyQuestions.slice(indexToRemove + 1);
        const surveyControlToUpdate = { ...(state.surveyQuestions.filter((q) => q.control_id === action.Cargo.controlId)[0]) };
        surveyControlToUpdate.is_utilized = parseInt(action.Cargo.isUtilized);
        if (action.Cargo.isUtilized == IsUtilizedValues.NO || action.Cargo.isUtilized == IsUtilizedValues.N_A) {
          surveyControlToUpdate.effective = 0;
        }
        else if (action.Cargo.isUtilized === IsUtilizedValues.YES || action.Cargo.isUtilized === IsUtilizedValues.PARTIAL) {
          surveyControlToUpdate.effective = -1;
        }
        const combined = [...first, surveyControlToUpdate, ...third]
        return { surveyQuestions: combined, lastUpdated: "" }
      }

      case "UPDATE_ENTITY_CONTROL_EFFECTIVENESS": {
        const indexToRemove = state.surveyQuestions.findIndex((q) => q.control_id === action.Cargo.controlId);
        const first = state.surveyQuestions.slice(0, indexToRemove);
        const third = state.surveyQuestions.slice(indexToRemove + 1);
        const surveyControlToUpdate = { ...(state.surveyQuestions.filter((q) => q.control_id === action.Cargo.controlId)[0]) };
        surveyControlToUpdate.effective = parseInt(action.Cargo.effectiveness);
        const combined = [...first, surveyControlToUpdate, ...third]
        return { surveyQuestions: combined, lastUpdated: "" }
      }

      case "UPDATE_SURVEY_QUESTIONS_LAST_UPDATED": {
        return {
          surveyQuestions: state.surveyQuestions,
          lastUpdated: action.Cargo
        };
      }
    }

    return state;
  }

  useEffect(() => {
    async function fxn() {
      if (allControls.length > 0 && controlOwners.length > 1 && selectedEntity.entityId !== "") {
        const response = await fetch(`${getBaseUrl()}/controlsSurvey?entityId=${selectedEntity.entityId}&user=${loggedInUser}`, { headers: new Headers({ "wowie": token }) });

        //Make a list of blank survey responses
        const _surveyQuestions: SurveyViewOfControlType[] = allControls
          .filter((ctrl: ControlType) => (ctrl.status === "active"))
          .map((ctrl: ControlType) => {
            return {
              id: ctrl.id,
              category_type_id: ctrl.category_type_id,
              category_id: ctrl.category_id,
              category_name: controlCategories.find((cc) => {
                return cc.id === ctrl.category_type_id;
              })?.name ?? "",
              conditional_use: ctrl.conditional_use,
              control_id: ctrl.id,
              control_name: ctrl.control_name,
              effective: -1, //a number from 0-4, -1 means "choose utilization"
              generalDescription: ctrl.generalDescription,
              hi_impact: ctrl.hi_impact,
              is_utilized: -1, // blank
              isFirstOfCategory: false,
              owner: controlOwners.filter((ctOwner) => {
                return ctOwner.id === ctrl.owner_id;
              })[0].name,
            }
          });

        const existingResponseData = await response.json();
        //try to get the result of a previously (completely filled) survey
        let existingResponses = [...existingResponseData.oldResponses];

        //If there are no responses from a previous, completely filled survey, then try to find responses from a partial survey
        if (existingResponses.length === 0) {
          existingResponses = [...existingResponseData.partialResponses];
        } else if (existingResponseData.partialResponses.length !== 0) {
          // there are responses from a previous, completely-filled survey, then overwrite the old response for each of them
          for (var i = 0; i < existingResponseData.partialResponses.length; i++) {
            let oldResponseFromCompletedSurvey = existingResponses.find((er) => er.control_id === existingResponseData.partialResponses[i].control_id);
            oldResponseFromCompletedSurvey.is_utilized = existingResponseData.partialResponses[i].is_utilized;
            oldResponseFromCompletedSurvey.effective = existingResponseData.partialResponses[i].effective;
          }
        }



        //We have no responses from any source, so just return the blank survey
        if (existingResponses.length === 0) {
          dispatch({ Type: "UPDATE_SURVEY_QUESTIONS", Cargo: { Surveys: _surveyQuestions } });
          return;
        }
        //We do have old responses, of some kind. Use them to bind the DDLs on the survey form
        const updated = _surveyQuestions.map((sq) => {
          const existingResponse = existingResponses.filter((a: { control_id: number, effective: number, is_utilized: number }) =>
            a.control_id == sq.control_id)[0];
          return {
            ...sq,
            effective: existingResponse?.effective ?? sq.effective,
            is_utilized: existingResponse?.is_utilized ?? sq.is_utilized
          }
        });


        dispatch({ Type: "UPDATE_SURVEY_QUESTIONS", Cargo: { Surveys: updated } });
        if (existingResponseData.oldResponses[0]?.last_update) {
          dispatch({ Type: "UPDATE_SURVEY_QUESTIONS_LAST_UPDATED", Cargo: prettyDateTime(existingResponseData.oldResponses[0].last_update) });
        }

      }
    }
    fxn();
  }, [allControls.length, controlOwners.length, selectedEntity.entityId, fetchCounter])

  const postSurveyAnswers = async () => {
    const answers = state.surveyQuestions.map((sq: SurveyViewOfControlType) => {
      return {
        control_id: sq.control_id,
        effective: sq.effective,
        is_utilized: sq.is_utilized
      }
    });
    const res = await fetch(`${getBaseUrl()}/controlsSurvey`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "wowie": token
      },
      method: "PUT",
      body: JSON.stringify({
        user: loggedInUser,
        entityId: selectedEntity.entityId,
        answers

      }),
    });
    setFetchCounter((old) => old + 1);
  }

  const postPartialSurvey = async () => {
    // Only post responses they've actually answered.  Unanswered questions will still have is_utilized == -1
    const answers = state.surveyQuestions
      .filter((sv: SurveyViewOfControlType) => sv.is_utilized > -1)
      .map((sq: SurveyViewOfControlType) => {
        return {
          control_id: sq.control_id,
          effective: sq.effective,
          is_utilized: sq.is_utilized
        }
      });
    const res = await fetch(`${getBaseUrl()}/partialControlsSurvey`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "wowie": token
      },
      method: "PUT",
      body: JSON.stringify({
        user: loggedInUser,
        entityId: selectedEntity.entityId,
        answers
      }),
    });

  }

  const copyFromExistingSurvey = async (entityId: string) => {
    const _surveyQuestions: SurveyViewOfControlType[] = allControls
      .filter((ctrl: ControlType) => (ctrl.status === "active"))
      .map((ctrl: ControlType) => {
        return {
          id: ctrl.id,
          category_type_id: ctrl.category_type_id,
          category_id: ctrl.category_id,
          category_name: ctrl.category_name,
          conditional_use: ctrl.conditional_use,
          control_id: ctrl.id,
          control_name: ctrl.control_name,
          effective: 1, //a number from 0-4
          generalDescription: ctrl.generalDescription,
          hi_impact: ctrl.hi_impact,
          is_utilized: -1,
          isFirstOfCategory: false,
          owner: controlOwners.filter((ctOwner) => {
            return ctOwner.id === ctrl.owner_id;
          })[0].name,
        }
      });

    const res = await fetch(`${getBaseUrl()}/controlsSurvey?entityId=${entityId}&user=${loggedInUser}`, { headers: new Headers({ "wowie": token }) });
    const data = await res.json();
    let existingResponses = data.oldResponses;

    const updated = _surveyQuestions.map((sq) => {
      const existingResponse = existingResponses.filter((a: { control_id: number, effective: number, is_utilized: number }) =>
        a.control_id == sq.control_id)[0];
      return {
        ...sq,
        effective: existingResponse.effective,
        is_utilized: existingResponse.is_utilized
      }
    })
    dispatch({ Type: "UPDATE_SURVEY_QUESTIONS", Cargo: { Surveys: updated } });
  }

  const refetchSurveyForSelectedSite = () => {
    setFetchCounter((old) => old + 1);
  }
  return (
    <SurveyContext.Provider value={{ state, dispatch, copyFromExistingSurvey, refetchSurveyForSelectedSite, postSurveyAnswers, postPartialSurvey }}>
      {children}
    </SurveyContext.Provider>
  );
};

import React, { useContext, useEffect, useState } from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import { Checkbox, FormField, Select, SelectProps } from "@amzn/awsui-components-react";
import { emptyIncident, IncidentSources, IncidentStatuses, IncidentSourceTypes} from "src/constants";
import { RiskContext } from "../../RiskProvider";
import { EditIncidentContext } from "./useEditIncident";
import { IncidentsContext } from "../IncidentsProvider";
import { IncidentRiskDBType, IncidentRiskDisplayType, SubRiskType } from "src/types";

const FieldsForCompletedIncidents = () => {
    const emptyIncidentRisk = { incidentID: "0", subRiskID: "0", isPrimary: 0 };
    const { incidentRisks, selectedIncidents } = useContext(IncidentsContext);
    const { state } = useContext(RiskContext);

    const {
        addRiskToIncident,
        removeRiskFromIncident,
        setSelectedStatus,
        setSelectedSource,
        setSelectedSourceType,
        selectedIncidentIndex,
        subRiskDisplayTypesAssociatedWithIncident,
        setSubRiskDisplayTypesAssociatedWithIncident,
        triageStatus,
    } = useContext(EditIncidentContext);

    // Values for the subrisk that is currently being added to this instance 
    const [selectedRiskID, setSelectedRiskID] = useState("0");
    const [selectedSubRiskID, setSelectedSubRiskID] = useState("0");
    const [currentSubRisk, setCurrentSubRisk] = useState<IncidentRiskDBType>(emptyIncidentRisk);
    const [subRisksBeingDisplayedInDDL, setSubRisksBeingDisplayedInDDL] = useState<SubRiskType[]>([]);
    const [selectedStatusOption, setSelectedStatusOption] = useState<SelectProps.Option>({ label: "", value: "" });
    const [selectedSourceOption, setSelectedSourceOption] = useState<SelectProps.Option>({ label: "", value: "" });
    const [selectedSourceTypeOption, setSelectedSourceTypeOption] = useState<SelectProps.Option>({ label: "", value: "" });

    const [newRiskPanelOpen, setNewRiskPanelOpen] = useState(false);

    const getSubRisksByRiskNumberThatAreNotAlreadyAssociated = (riskNum: string, currentlyAssociatedSubRisks: IncidentRiskDBType[]) => {
        const rawSubRisks = state.subRisks.filter((sr) => sr.risk_id == Number.parseInt(riskNum));
        if (currentlyAssociatedSubRisks.length === 0) {
            return rawSubRisks;
        }
        const currentlySelectedSubRiskIDs = currentlyAssociatedSubRisks.map((sr) => parseInt(sr.subRiskID));
        const subRisksThatArentAlreadyPicked = rawSubRisks.filter((raw) => {
            const justTheSubRiskIDs = currentlyAssociatedSubRisks
                .map((x) => x.subRiskID);

            const theSubRiskIDsThatArentAlreadyPicked = justTheSubRiskIDs.filter((thing) => currentlySelectedSubRiskIDs.indexOf(raw.acs_risk_dim_sk) == -1)
            return theSubRiskIDsThatArentAlreadyPicked.length > 0;
        });

        return subRisksThatArentAlreadyPicked;
    }
    let thisIncident = selectedIncidents[selectedIncidentIndex] || emptyIncident;
    let subRiskOptions;

    const statusOptions = IncidentStatuses.map((stat) => {
        return { label: stat, value: stat }
    });

    const sourceOptions = IncidentSources.map((source) => {
        return { label: source, value: source };
    });

    const sourceTypeOptions = IncidentSourceTypes.map((st) => {
        return { label: st, value: st };
    });



    //Only run this one when you load a new incident
    useEffect(() => {
        //Figure out which riskCategories and subRisks go in the DDLs
        const {
            id,
            status,
            source,
            source_type,
            threat_originator,
        } = thisIncident;

        setSelectedStatusOption(statusOptions.filter((e) => e.value === status)[0]);
        setSelectedSourceOption(sourceOptions.filter((e) => e.value === source)[0]);
        setSelectedSourceTypeOption(sourceTypeOptions.filter((e) => e.value === source_type)[0]);

        if (id !== "") {
            let subRisksForThisIncident = incidentRisks.filter((ir) => ir.incidentID === thisIncident.id);


            const subRiskIdsForThisIncident = subRisksForThisIncident.map((ir) => Number.parseInt(ir.subRiskID));
            //the ones to show in our list of already-associated subRisks
            const relevantSubRiskDisplayTypes: IncidentRiskDisplayType[] = state.subRisks
                .filter((sr) => subRiskIdsForThisIncident.some((srThisIncident) => sr.acs_risk_dim_sk === srThisIncident))
                .map((rsr) => {
                    const primary = subRisksForThisIncident.find((f) => parseInt(f.subRiskID) == rsr.acs_risk_dim_sk)?.isPrimary!
                    return {
                        subRiskID: rsr.acs_risk_dim_sk.toString(),
                        subRiskName: rsr.sub_risk_full_name,
                        incidentID: thisIncident.id,
                        isPrimary: primary
                    }
                });
            setSubRiskDisplayTypesAssociatedWithIncident(relevantSubRiskDisplayTypes);
        }
    }, [incidentRisks?.length, thisIncident.id]);

    //Run this one whenever you select a new risk category
    useEffect(() => {
        // only show the ones that are not already associated with this incident
        setSubRisksBeingDisplayedInDDL(getSubRisksByRiskNumberThatAreNotAlreadyAssociated(selectedRiskID, subRiskDisplayTypesAssociatedWithIncident));
    }, [selectedRiskID]);

    const riskCategoryOptions = state.risks.map((r) => {
        return (
            <option key={`risk_option_${r.risk_number + 1}`} value={r.risk_number}>
                {r.risk_name_full}
            </option>
        );
    });

    riskCategoryOptions.unshift(
        <option key={`risk_option_0`} value={0}>
            Choose
        </option>
    );

    subRiskOptions = subRisksBeingDisplayedInDDL.map((sr) => {
        return (
            <option
                key={`sr_option_${sr.acs_risk_dim_sk}`}
                value={sr.acs_risk_dim_sk}
            >
                {sr.sub_risk_full_name}
            </option>
        );
    });

    subRiskOptions.unshift(
        <option key={`sr_option_choose`} value={0}>
            Choose
        </option>
    );

    return (
        triageStatus === "complete" ? (
            <div className="right-col">
                <fieldset>
                   { subRiskDisplayTypesAssociatedWithIncident.length > 0 &&  <h3>Attributed risk(s)</h3> }
                    <ul className="incident-sub-risk-association">
                        {subRiskDisplayTypesAssociatedWithIncident.sort((a: IncidentRiskDBType, b: IncidentRiskDBType) => {
                            if (a.isPrimary) return -1;
                            if (b.isPrimary) return 1;
                            return 0;
                        }).map((risk, idx) => {
                            const theName = state.subRisks.find((a) => a.acs_risk_dim_sk.toString() == risk.subRiskID)?.sub_risk_full_name;
                            return (<li key={risk.subRiskID + idx}>
                                <div className="spread-apart">
                                    <span className="sub_risk_name_and_primary_label">
                                        <span className={`sub-risk-name ${risk.isPrimary && "primary-risk"}`}>{theName}</span>
                                        <span>{`${risk.isPrimary === 1 ? "(primary)" : ""}`}</span>
                                    </span>
                                    <span className="button-holder"><Button onClick={(_evt) => removeRiskFromIncident(risk.subRiskID)}>remove</Button></span>
                                </div>
                            </li>)
                        })}
                    </ul>

                    {!newRiskPanelOpen && <div className="space-above">
                    <label className="mr-6">Attribute a</label><Button onClick={(_evt) => setNewRiskPanelOpen(true)}>new risk</Button>
                    </div>
                    }

                    {newRiskPanelOpen && <>
                        <div className="space-above">
                            <h4>Attribute new risk</h4>
                            <div className="associate-risk-with-incident">
                                <label className="mx-2">Risk Category:</label>
                                <select
                                    value={selectedRiskID}
                                    onChange={(evt) => {
                                        setSelectedRiskID(evt.currentTarget.value);
                                    }}
                                >
                                    {riskCategoryOptions}
                                </select>
                            </div>

                            {selectedRiskID !== "0" && (
                                <div className="associate-risk-with-incident">
                                    <label className="mx-2">Sub Risk:</label>
                                    <select
                                        value={selectedSubRiskID}
                                        onChange={(evt) => {
                                            setSelectedSubRiskID(evt.target.value);
                                        }}
                                    >
                                        {subRiskOptions}
                                    </select>
                                </div>
                            )}

                        </div>
                        <div className="space-above">
                            {selectedSubRiskID !== "0" && <Checkbox
                                checked={currentSubRisk.isPrimary === 1}
                                onChange={(evt) => { setCurrentSubRisk({ ...currentSubRisk, isPrimary: evt.detail.checked ? 1 : 0 }) }}>is Primary</Checkbox>
                            }
                        </div>
                        <div className="space-above">
                            <Button disabled={selectedSubRiskID === "0" || selectedRiskID === "0"} onClick={(_evt) => {
                                //postNewIncidentRisk(Number.parseInt(selectedSubRiskID),thisIncident.id);
                                const theName = state.subRisks.find((a) => a.acs_risk_dim_sk == parseInt(selectedSubRiskID))?.sub_risk_full_name;
                                addRiskToIncident({
                                    subRiskID: selectedSubRiskID,
                                    incidentID: thisIncident.id,
                                    isPrimary: currentSubRisk.isPrimary,
                                    subRiskName: theName!
                                })
                                setSelectedRiskID("0");
                                setSelectedSubRiskID("0");
                                setCurrentSubRisk(emptyIncidentRisk)
                            }}>Attribute to incident</Button>
                        </div>
                    </>
                    }
                </fieldset>
                <div>
                    <SpaceBetween direction="vertical" size="xs">
                        <FormField label="Status:">
                            <Select
                                selectedOption={selectedStatusOption}
                                onChange={({ detail }) => {
                                    setSelectedStatusOption(detail.selectedOption);
                                    setSelectedStatus(detail.selectedOption.value!)
                                }}
                                options={statusOptions}
                            />
                        </FormField>

                        <FormField label="Source:">
                            <Select
                                selectedOption={selectedSourceOption}
                                onChange={({ detail }) => {
                                    setSelectedSourceOption(detail.selectedOption);
                                    setSelectedSource(detail.selectedOption.value!);
                                }}
                                options={sourceOptions}
                            />
                        </FormField>

                        <FormField label="Source type:">
                            <Select
                                selectedOption={selectedSourceTypeOption}
                                onChange={({ detail }) => {
                                    setSelectedSourceType(detail.selectedOption.value!);
                                    setSelectedSourceTypeOption(detail.selectedOption);
                                }}
                                options={sourceTypeOptions}
                            />
                        </FormField>

                    </SpaceBetween>
                </div>
            </div>
        ) : <span />);
}

export default FieldsForCompletedIncidents;
import React, { useContext, useEffect, useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  AttributeEditor,
  Container,
  Header,
  Select,
  SelectProps,
  Form,
  FormField,
  FormFieldProps,
  Multiselect,
  MultiselectProps,
  Textarea,
  TextareaProps,
  Input,
  InputProps,
  AttributeEditorProps,
} from "@amzn/awsui-components-react";
import { ModalContext } from "./useModal";
import { RiskContext } from "./RiskProvider";

const EditRiskModal = () => {
  const { isShowing, hideModals } = useContext(
    ModalContext
  );
  const { state, createRisk, updateRisk, dispatch } = useContext(RiskContext);
  const [brandNewRiskCategory, setBrandNewRiskCategory] = useState(true);
  const [
    selectedRiskCategoryName,
    setSelectedRiskCategoryName,
  ] = React.useState("");

  const [textareaGDValue, setTextareaGDValue] = React.useState("");

  const [selectedStatus, setSelectedStatus] = React.useState<
    SelectProps.Option
  >({});

  const statusOptions = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ];
  useEffect(() => {
    setSelectedRiskCategoryName(state.selectedRiskCategory.risk_name);
    setTextareaGDValue(state.selectedRiskCategory.description);
    setSelectedStatus(statusOptions.filter((e) => e.value == state.selectedRiskCategory.active_flag.toString())[0]);
    setBrandNewRiskCategory(state.selectedRiskCategory.risk_number === 0);
  }, [state.selectedRiskCategory.risk_number]);



  return (
    <Modal
      onDismiss={() => hideModals()}
      visible={isShowing("editRisk")}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={(_event) => {  
              dispatch({Type: "SET_SELECTED_RISK_CATEGORY_TO_EMPTY", Cargo: 0}); 
              hideModals()}}>
              Cancel
            </Button>
            <Button variant="primary" onClick={(_evt) => {
              if (brandNewRiskCategory) {
                createRisk(selectedRiskCategoryName, parseInt(selectedStatus.value!), textareaGDValue)
              } else {
                updateRisk(state.selectedRiskCategory.risk_number, selectedRiskCategoryName, parseInt(selectedStatus.value!), textareaGDValue)
              }
              hideModals();
              dispatch({Type: "SET_SELECTED_RISK_CATEGORY_TO_EMPTY", Cargo: 0})
            }}>Save</Button>
          </SpaceBetween>
        </Box>
      }
      header={brandNewRiskCategory ? "New risk category" : `Edit ${selectedRiskCategoryName}`}
    >
      <Form header={<Header variant="h2">Risk Category</Header>}>
        <FormField label="Risk Category Name">
          <Input
            value={selectedRiskCategoryName}
            onChange={({ detail }) => setSelectedRiskCategoryName(detail.value)}
          />
        </FormField>
        <FormField label="General Description">
          <Textarea
            onChange={({ detail }) => setTextareaGDValue(detail.value)}
            value={textareaGDValue}
            placeholder="Add description of the Risk Category here."
          ></Textarea>
        </FormField>
        <FormField label="Status:">
          <Select
            selectedOption={selectedStatus}
            onChange={({ detail }) => setSelectedStatus(detail.selectedOption)}
            options={statusOptions}
          />
        </FormField>
      </Form>
    </Modal>
  );
};

export default EditRiskModal;

import React, { useContext } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { Container } from "@amzn/awsui-components-react";
import { ModalContext } from "../useModal";

const ReadOnlyControlsTableInstructionsModal = () => {
  const { hideModals, isShowing } = useContext(ModalContext);
  return (
    <Modal
      onDismiss={() => hideModals()}
      visible={isShowing("readOnlyControlsTableInstructionsModal")}
    >
      <Container>
        <div className="modal-body">
          Hey this is the ReadOnlyControlsTableInstructionsModal
        </div>
      </Container>
    </Modal >
  );
};

export default ReadOnlyControlsTableInstructionsModal;

import React, { useContext, useState } from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import { ControlType } from "src/types";
import { ControlDataContext } from "../ControlsProvider";
import { paginationLabels } from "../../constants";
import { Icon } from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import EmptyState from "../EmptyState";
import { RoleContext } from "../RoleProvider";
import { ModalContext } from "../useModal";


const ReadOnlyControlsTableForWorkbench = () => {
  const { allControls, controlCategories, setSelectedControlID } = useContext(ControlDataContext);
  const { roleFetchComplete, userIsRiskAdmin } = useContext(RoleContext);
  const { showModal, hideModals } = useContext(ModalContext);


  const [preferences, setPreferences] = useState({
    pageSize: 50,
    contentDisplay: [
      { id: "category_type_id", visible: true },
      { id: "control_name", visible: true },
      { id: "owner", visible: true },
      { id: "status", visible: true },
    ],
  });

  const {
    items: itemsFromUseCollection,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(allControls, {
    filtering: {
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  // if(!userIsRiskAdmin && roleFetchComplete){
  //   return <Navigate to="/" />;
  // }

  if (!roleFetchComplete) {
    return <EmptyState title="Loading controls..." subtitle="" action="" />
  }

  return (
    <Table
      {...collectionProps}
      columnDefinitions={[
        {
          id: "category_type_id",
          header: <span data-testid="category-type-header">Category Type</span>,
          cell: (e: ControlType) => (
            <span>
              {
                controlCategories.filter(
                  (cc) => cc.id === e.category_type_id
                )[0]!.name
              }
            </span>
          ),
          sortingField: "category_type_id",
          isRowHeader: true,
          width: 200,
        },
        {
          id: "control_name",
          header: "Control name",
          cell: (e: ControlType) => <Button onClick={() => {
            setSelectedControlID(e.id);
            hideModals();
            showModal("controlDetailsModal");
          }}>{e.control_name}</Button>,
          sortingField: "control_name",
          isRowHeader: true
        },
        {
          id: "owner",
          header: "Owner",
          cell: (e: ControlType) => <span data-testid="owner">{e.owner_name}</span>,
          sortingField: "owner_name"
        },
        {
          id: "status",
          header: "Status",
          cell: (e: ControlType) => {
            return e.status === "active" ? (
              <Box><Icon data-testid="status-active" name="security" size="small" variant="success" /> Active</Box>
            ) : (
              <Box><Icon data-testid="status-inactive" name="security" size="small" variant="error" /> Inactive</Box>
            );
          },
          sortingField: "status"
        },
      ]}
      columnDisplay={preferences.contentDisplay}
      enableKeyboardNavigation
      items={itemsFromUseCollection}
      loadingText="Loading resources"
      filter={
        <TextFilter
          {...filterProps}
          countText={filteredItemsCount + " matches"}
          filteringPlaceholder="Find a control"
          filteringAriaLabel="Filter controls"
        />
      }

      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
    />
  );
};

export default ReadOnlyControlsTableForWorkbench;

import React, { useContext } from "react";
import Table, { TableProps } from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import { useCollection } from "@amzn/awsui-collection-hooks";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import { paginationLabels } from "../../constants";
import { ContentDisplayItem, IncidentType } from "../../types";
import { IncidentsContext } from "../incidents/IncidentsProvider"
import { ModalContext } from "../useModal";
import EmptyState from "../EmptyState";
import { prettyDateString } from "src/utils";
import { useLocalStorage } from "src/utils/use-local-storage";

const IncidentData = () => {
  const { completeIncidents } = useContext(IncidentsContext);
  const { selectedEntity } = useContext(ModalContext);

  function getMatchesCountText(count: number | undefined) {
    return count === 1 ? `1 match` : `${count} matches`;
  }

  const DEFAULT_PREFERENCES = {
    pageSize: 10,
    contentDisplay: [
      {
        id: "date_reported",
        visible: true,
      },
      {
        id: "region",
        visible: true,
      },
      {
        id: "country",
        visible: true,
      },
      {
        id: "location",
        visible: true,
      },
      {
        id: "title",
        visible: true,
      },
      {
        id: "display_id",
        visible: true,
      },
      {
        id: "sub_risk_full_name",
        visible: true,
      },
      {
        id: "bookmarked",
        visible: true,
      },
      {
        id: "needs_review",
        visible: true,
      },
    ]
  }

  const [preferences, setPreferences] = useLocalStorage<{
    pageSize: number,
    contentDisplay: readonly ContentDisplayItem[],
  }>("triaged-incidents-table-preferences", DEFAULT_PREFERENCES);


  const {
    items: itemsFromUseCollection,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(completeIncidents.filter((incident) => incident.location == selectedEntity.site), {
    filtering: {
      empty: (
        <EmptyState
          title="No Incidents Found"
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
      filteringFunction: (item, filteringText) => {
        return item.region?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase())
          || item.location?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase())
          || item.title?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase())
          || item.country?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase())
          || item.date_reported?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase())
          || item.display_id?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase())
          || item.sub_risk_full_name?.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase())
          || (item.bookmarked == 1 && filteringText.toLocaleLowerCase().includes("bookmark"))
          || (item.needs_review == 1 && filteringText.toLocaleLowerCase().includes("review"));
      },
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  return (<div className="site-tab-content">
    <Table
      {...collectionProps}
      columnDefinitions={[
        {
          id: "date_reported",
          header: "Date reported",
          cell: (e: IncidentType) => prettyDateString(e.date_reported),
          sortingField: "date_reported",
          isRowHeader: true,
        },
        {
          id: "region",
          header: "Region",
          cell: (e: IncidentType) => e.region,
          sortingField: "region",
        },
        {
          id: "country",
          header: "Country",
          cell: (e: IncidentType) => e.country,
          sortingField: "country",
        },
        { id: "type", header: "Type", cell: (e: IncidentType) => e.type },
        {
          id: "location",
          header: "Location",
          cell: (e: IncidentType) => e.location,
          sortingField: "location",
        },
        {
          id: "title",
          header: "Title",
          cell: (e: IncidentType) => e.title,
          sortingField: "title",
        },
        {
          id: "display_id",
          header: "Display id",
          cell: (e: IncidentType) => e.display_id,
          sortingField: "display_id",
        },
        {
          id: "sub_risk_full_name",
          header: "Risk",
          cell: (e: IncidentType) => e.sub_risk_full_name,
          sortingField: "sub_risk_full_name",
        },
        {
          id: "bookmarked",
          header: "Bookmarked",
          cell: (e: IncidentType) => e.bookmarked == 1 ? "bookmarked" : "no",
          sortingField: "bookmarked",
        },
        {
          id: "needs_review",
          header: "Needs review",
          cell: (e: IncidentType) => e.needs_review == 1 ? "yes" : "no",
          sortingField: "needs_review",
        },
      ]}
      columnDisplay={preferences.contentDisplay}
      enableKeyboardNavigation
      items={itemsFromUseCollection}
      loadingText="Loading incidents"
      trackBy="display_id"
      empty={
        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No Incidents for {selectedEntity.site}</b>
          </SpaceBetween>
        </Box>
      }
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount)}
          filteringPlaceholder="Search by location, title, etc."
          filteringAriaLabel="Filter incidents"
        />
      }
      header={
        <Header>{selectedEntity.site} incidents</Header>
      }
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={{
            pageSize: preferences.pageSize,
            contentDisplay: preferences.contentDisplay,
          }}
          onConfirm={({ detail }) => {
            const { pageSize, contentDisplay } = detail;
            if (typeof pageSize === "number" && contentDisplay) {
              setPreferences({ pageSize, contentDisplay });
            }
          }}
          pageSizePreference={{
            title: "Select page size",
            options: [
              { value: 10, label: "10 incidents" },
              { value: 20, label: "20 incidents" },
            ],
          }}
          contentDisplayPreference={{
            options: [
              {
                id: "date_reported",
                label: "Date reported",
                alwaysVisible: true,
              },
              { id: "region", label: "Region" },
              { id: "country", label: "country" },
              { id: "location", label: "Location" },
              { id: "title", label: "Title", alwaysVisible: true },
              { id: "display_id", label: "Display id" },
              { id: "sub_risk_full_name", label: "Risk" },
              { id: "bookmarked", label: "Bookmarked" },
              { id: "needs_review", label: "needs_review" },
            ],
          }}
        />
      }
      wrapLines={true}
    />
  </div>);
}

export default IncidentData;
import React, { createContext, useContext, useState, useEffect } from "react";
import { emptyIncident, EmptyIncidentRisk } from "../../constants";
import { IncidentRiskDBType, IncidentType, IncidentsGroupedByDate } from "src/types";
import { RoleContext } from "../RoleProvider";
import { getBaseUrl, getMonthAndYear } from "src/utils";

const initialState = {
    bookmarkedIncidentsGroupedByDay: {},
    completeIncidents: [emptyIncident],
    duplicateIncidentsGroupedByDay: {},
    incidentHasPrimaryRisk: (_incidentId: string) => false,
    incidentRisks: [EmptyIncidentRisk],
    naAndDuplicateIncidents: [emptyIncident],
    needsReviewIncidentsGroupedByDay: {},
    putIncident: (_incident: IncidentType, _risksAssociatedWithThisIncident: IncidentRiskDBType[]) => { },
    nonAttributableIncidentsGroupedByMonth: {},//monthString
    rawIncidents: [emptyIncident],
    rawIncidentsGroupedByDay: {},
    selectedIncidents: [emptyIncident],
    setNeedsRefetch: () => { },
    setSelectedIncidents: (_incidents: IncidentType[]) => { },
    triagedIncidentsGroupedByMonth: {},
    untriagedIncidents: [emptyIncident],
};

export const IncidentsContext = createContext(initialState);

export const IncidentsProvider = (props: { children: JSX.Element }) => {

    const [untriagedIncidents, setUntriagedIncidents] = useState(initialState.untriagedIncidents);
    const [rawIncidents, setRawIncidents] = useState([emptyIncident]);
    const [rawIncidentsGroupedByDay, setRawIncidentsGroupedByDay] = useState<IncidentsGroupedByDate>({});
    const [needsReviewIncidentsGroupedByDay, setNeedsReviewIncidentsGroupedByDay] = useState<IncidentsGroupedByDate>({});
    const [bookmarkedIncidentsGroupedByDay, setBookmarkedIncidentsGroupedByDay] = useState<IncidentsGroupedByDate>({});
    const [duplicateIncidentsGroupedByDay, setDuplicateIncidentsGroupedByDay] = useState<IncidentsGroupedByDate>({});
    const [nonAttributableIncidentsGroupedByMonth, setNonAttributableIncidentsGroupedByMonth] = useState<IncidentsGroupedByDate>({});
    const [triagedIncidentsGroupedByMonth, setTriagedIncidentsGroupedByMonth] = useState<IncidentsGroupedByDate>({});
    const [completeIncidents, setCompleteIncidents] = useState(initialState.completeIncidents);
    const [naAndDuplicateIncidents, setNaAndDuplicateIncidents] = useState(initialState.naAndDuplicateIncidents);
    const [selectedIncidents, setSelectedIncidents] = useState([emptyIncident]);
    const [incidentRisks, setIncidentRisks] = useState(initialState.incidentRisks);
    const untriagedEndpoint = `${getBaseUrl()}/untriagedIncidents`;
    const triagedEndpoint = `${getBaseUrl()}/triagedIncidents`;
    const { loggedInUser, token, mostPowerfulRole, authenticated, fakeLoggedInUser, userIsRiskAdmin, userIsRiskManager, roleFetchComplete } = useContext(RoleContext);
    const [incidentsAreDirtyCounter, setIncidentsAreDirtyCounter] = useState(0);


    const dateSorter = (a: any, b: any) => {
        const dateStringA = a.date_reported.split(" ")[0]
        const dateStringB = b.date_reported.split(" ")[0]
        const dateA = new Date(dateStringA);
        const dateB = new Date(dateStringB);
        if (dateA < dateB) {
            return -1
        } if (dateA > dateB) {
            return 1
        } return 0;
    };

    const fetchTriagedIncidents = async () => {
        let url = triagedEndpoint;
        if (userIsRiskAdmin && fakeLoggedInUser !== "") {
            url = `${triagedEndpoint}?alias=${fakeLoggedInUser}`;
        }
        else if (mostPowerfulRole === "manager") {
            url = `${triagedEndpoint}?alias=${loggedInUser}`;
        }
        const res = await fetch(url,
            { headers: new Headers({ "wowie": token }) });
        return res.json();
    }

    const fetchUntriagedIncidents = async () => {
        const res = await fetch(untriagedEndpoint,
            { headers: new Headers({ "wowie": token }) });
        return res.json();
    }

    const fetchIncidentRisks = async () => {
        const res = await fetch(`${getBaseUrl()}/riskIncident`,
            { headers: new Headers({ "wowie": token }) });
        return res.json();
    }


    // PUTs a request to update the incident and refreshes the references to the incident in either the TriagedIncidents or UntriagedIncidents array
    const putIncident = async (updatedIncident: IncidentType, risksAssociatedWithThisIncident: IncidentRiskDBType[]) => {
        const response = await fetch(`${getBaseUrl()}/incident?incidentRedux=true`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "wowie": token
            },
            method: "PUT",
            body: JSON.stringify({
                analystNotes: updatedIncident.analyst_notes,
                associatedRisks: risksAssociatedWithThisIncident,
                bookmarked: updatedIncident.bookmarked,
                city: updatedIncident.city,
                country: updatedIncident.country,
                duplicate: updatedIncident.duplicate,
                incidentID: updatedIncident.id,
                title: updatedIncident.title,
                type: updatedIncident.type,
                notes: updatedIncident.notes,
                location: updatedIncident.location,
                region: updatedIncident.region,
                needs_review: updatedIncident.needs_review,
                source: updatedIncident.source,
                stateProvince: updatedIncident.state_province,
                status: updatedIncident.status,
                sourceType: updatedIncident.source_type,
                triageStatus: updatedIncident.triage_status,
                threatOriginator: "Unknown",
                updatedBy: loggedInUser
            })
        });
        const result = await response.json();

        if (response.status == 200) {
            setIncidentsAreDirtyCounter((prev) => prev + 1);
            const tempIncidentRisks = [...incidentRisks, ...risksAssociatedWithThisIncident];
            setIncidentRisks(tempIncidentRisks);
        }
    };

    useEffect(() => {
        const fetchIncidentsForManager = async () => {
            const [complete, incidRisks] = await Promise.all([
                fetchTriagedIncidents(),
                fetchIncidentRisks()
            ]);

            setCompleteIncidents(complete.incidents);
            setIncidentRisks(incidRisks);
        }

        const fetchIncidentsForAdmin = async () => {
            const [complete, incomplete, incidRisks] = await Promise.all([
                fetchTriagedIncidents(),
                fetchUntriagedIncidents(),
                fetchIncidentRisks()
            ]);

            setCompleteIncidents(complete.incidents);
            setUntriagedIncidents(incomplete.incidents);
            setIncidentRisks(incidRisks.incidentRisks);
            const combined: IncidentType[] = complete?.incidents?.concat(incomplete.incidents);
            setNaAndDuplicateIncidents(combined?.filter((incd) => ((incd?.duplicate === 1) || (incd?.triage_status === "n/a"))));

            if (combined !== undefined) {
                const sortedIncidents = combined
                    .filter((x: any) => x?.date_reported?.trim().length > 0)//pending some sort of extraction-time cleanup of dateless incidents
                    .sort(dateSorter);

                const tempRawIncidents: IncidentType[] = [];
                const tempCompleteIncidents: IncidentType[] = [];
                const tempRawIncidentsGroupedByDay: IncidentsGroupedByDate = {};
                const tempNeedsReviewIncidentsGroupedByDay: IncidentsGroupedByDate = {};
                const tempBookmarkedIncidentsGroupedByDay: IncidentsGroupedByDate = {};
                const tempDuplicateIncidentsGroupedByDay: IncidentsGroupedByDate = {};
                const tempnonAttributableIncidentsGroupedByMonth: IncidentsGroupedByDate = {};
                const tempTriagedIncidentsGroupedByDay: IncidentsGroupedByDate = {};

                for (let i = 0; i < sortedIncidents.length; i++) {
                    if (sortedIncidents[i].date_reported.length > 10) {
                        const theDateString = sortedIncidents[i].date_reported.split("T")[0];
                        const dateParts = theDateString.split("-");
                        const theDate = new Date(parseInt(dateParts[0]), parseInt(dateParts[1]), parseInt(dateParts[2]));
                        const monthString = getMonthAndYear(theDate);

                        if (sortedIncidents[i].needs_review === 1) {
                            if (!tempNeedsReviewIncidentsGroupedByDay[theDateString]) {
                                tempNeedsReviewIncidentsGroupedByDay[theDateString] = [];
                            }
                            tempNeedsReviewIncidentsGroupedByDay[theDateString].push(sortedIncidents[i]);
                        }
                        if (sortedIncidents[i].bookmarked === 1) {
                            if (!tempBookmarkedIncidentsGroupedByDay[theDateString]) {
                                tempBookmarkedIncidentsGroupedByDay[theDateString] = [];
                            }
                            tempBookmarkedIncidentsGroupedByDay[theDateString].push(sortedIncidents[i]);
                        }
                        if (sortedIncidents[i].duplicate === 1) {
                            if (!tempDuplicateIncidentsGroupedByDay[theDateString]) {
                                tempDuplicateIncidentsGroupedByDay[theDateString] = [];
                            }
                            tempDuplicateIncidentsGroupedByDay[theDateString].push(sortedIncidents[i]);
                        }
                        if (sortedIncidents[i].triage_status === "raw") {
                            tempRawIncidents.push(sortedIncidents[i]);
                            if (!tempRawIncidentsGroupedByDay[theDateString]) {
                                tempRawIncidentsGroupedByDay[theDateString] = [];
                            }
                            tempRawIncidentsGroupedByDay[theDateString].push(sortedIncidents[i]);
                        }
                        if (sortedIncidents[i].triage_status === "n/a") {
                            if (!tempnonAttributableIncidentsGroupedByMonth[monthString]) {
                                tempnonAttributableIncidentsGroupedByMonth[monthString] = [];
                            }
                            tempnonAttributableIncidentsGroupedByMonth[monthString].push(sortedIncidents[i]);
                        }
                        if (sortedIncidents[i].triage_status === "complete") {
                            tempCompleteIncidents.push(sortedIncidents[i])
                            if (!tempTriagedIncidentsGroupedByDay[monthString]) {
                                tempTriagedIncidentsGroupedByDay[monthString] = [];
                            }
                            tempTriagedIncidentsGroupedByDay[monthString].push(sortedIncidents[i]);
                        }
                    }
                };

                setNeedsReviewIncidentsGroupedByDay(tempNeedsReviewIncidentsGroupedByDay);
                setBookmarkedIncidentsGroupedByDay(tempBookmarkedIncidentsGroupedByDay);
                setDuplicateIncidentsGroupedByDay(tempDuplicateIncidentsGroupedByDay);
                setNonAttributableIncidentsGroupedByMonth(tempnonAttributableIncidentsGroupedByMonth);
                setTriagedIncidentsGroupedByMonth(tempTriagedIncidentsGroupedByDay);
                setCompleteIncidents(tempCompleteIncidents);
                setRawIncidents(tempRawIncidents);
                setRawIncidentsGroupedByDay(tempRawIncidentsGroupedByDay);
            }


        }
        const fxn = async () => {
            if (loggedInUser.length > 1 && authenticated && roleFetchComplete) {
                if (userIsRiskAdmin) {
                    fetchIncidentsForAdmin()
                } else if (userIsRiskManager) {
                    fetchIncidentsForManager()
                }
            }
        };
        fxn();
    }, [loggedInUser, incidentsAreDirtyCounter, completeIncidents.length, untriagedIncidents?.length, fakeLoggedInUser, roleFetchComplete]);

    const setNeedsRefetch = () => {
        setIncidentsAreDirtyCounter((prev) => prev + 1);
    }

    const incidentHasPrimaryRisk = (incidentId: string) => {
        const primaryRisk = incidentRisks.find((ir: IncidentRiskDBType) => (ir.incidentID == incidentId && ir.isPrimary === 1));
        return primaryRisk !== undefined;
    }

    return (<IncidentsContext.Provider value={{
        incidentHasPrimaryRisk,
        untriagedIncidents, rawIncidents, rawIncidentsGroupedByDay, needsReviewIncidentsGroupedByDay, bookmarkedIncidentsGroupedByDay,
        duplicateIncidentsGroupedByDay, nonAttributableIncidentsGroupedByMonth, triagedIncidentsGroupedByMonth, completeIncidents, naAndDuplicateIncidents,
        incidentRisks, putIncident, selectedIncidents,
        setSelectedIncidents, setNeedsRefetch
    }}>
        {props.children}
    </IncidentsContext.Provider>)
}
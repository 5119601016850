import React, { useContext, useRef, useState } from "react";
import jsPDF from 'jspdf';
import { Box, Button } from "@amzn/awsui-components-react";
import { ControlDataContext } from "../ControlsProvider";
import { IsUtilizedValues, EffectivenessDefinitions } from "../../constants";
import { SurveyContext } from "../SurveyProvider";
import { ModalContext } from "../useModal";


const SecurityControlsManagementPlan = () => {
  const { selectedEntity } = useContext(ModalContext);
  const { controlCategories } = useContext(ControlDataContext);
  const { state } = useContext(SurveyContext);
  const scmpRef = useRef<HTMLDivElement>(null);

  const textByCategory = controlCategories.map((ctrlCat) => {
    return {
      categoryName: ctrlCat.name,
      controls: state.surveyQuestions
        .filter((sq1) => sq1.category_type_id === ctrlCat.id)
        .map((sq) => {
          return {
            name: sq.control_name,
            effective: sq.effective,
            utilized: sq.is_utilized
          }
        })
    }
  })

  const scmpContainerStyle = {
    margin: "1rem",
    padding: "1rem"
  }

  const categoryListStyle: React.CSSProperties = {
    marginTop: "0"
  }

  const controlListItemStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    listStyle: "none"
  }

  const controlCategoryStyle: React.CSSProperties = {
    textAlign: "right",
    width: "750px",
  }

  const scmpLabel: React.CSSProperties = {
    color: "#666",
    display: "inline-block",
    marginLeft: "20px"
  }

  const isUtilizedValueStyle: React.CSSProperties = {
    display: "inline-block",
    textAlign: "left",
    width: "65px"
  }

  const effectivenessValueStyle: React.CSSProperties = {
    display: "inline-block",
    textAlign: "left",
    width: "120px"
  }

  const printButtonStyle: React.CSSProperties = {
    float: "right",
    display: "inline-block"
  }

  return (<Box>
    <div ref={scmpRef} style={scmpContainerStyle}>
      <div>
        <h2 style={{ display: "inline-block", margin: "0" }}>{selectedEntity.site} security controls management plan</h2>
        <span style={printButtonStyle}><Button onClick={() => {
          const doc = new jsPDF({ unit: 'px', hotfixes: ['px_scaling'] });
          doc.html(scmpRef.current!, {
            async callback(doc) {
              await doc.save('pdf_name');
            },
          });
        }}>Print</Button></span>
      </div>
      {
        textByCategory.map((tc) => {
          return (<div key={`cat-map-${tc.categoryName}`} style={categoryListStyle}>
            <h3>{tc.categoryName}</h3>
            <ul style={categoryListStyle}>
              {tc.controls.map((c) => (<li style={controlListItemStyle} key={`ctrl-list-item-${c.name}`}>
                <div style={controlCategoryStyle}>
                  <span key={`ctrl-name-${c.name}`}>{c.name}</span>
                  <label style={scmpLabel}>Utilized</label><strong style={isUtilizedValueStyle}>{IsUtilizedValues[c.utilized]}</strong>
                  <label style={scmpLabel}>Effectiveness</label><strong style={effectivenessValueStyle}>{EffectivenessDefinitions[c.effective] || "NOT SET"}</strong>
                </div>
              </li>))}
            </ul>
          </div>);
        })
      }
    </div>
  </Box>
  );
};

export default SecurityControlsManagementPlan;

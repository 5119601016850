import React, { useContext, useState, useEffect } from "react";
import { IncidentsContext } from "./IncidentsProvider";

const ViewIncident = () => {
  const {selectedIncidents} = useContext(IncidentsContext);

  const {
    date_reported,
    location,
    notes,
    region,
    title,
    type,
  } = selectedIncidents[0];

  
  return (<div className="body">
    <div className="modal-body">
      <div className="admin-section">
        <div className="space-above">Title: {title.length > 0 ? title : "(none)"}</div>
        <div className="space-above">Date reported: {date_reported}</div>
        <div className="space-above">Region: {region}</div>
        <div className="space-above">Location: {location}</div>
        <div className="space-above">Type: {type}</div>
        <div className="space-above">Notes: <div className="space-above grow-to-fit">{notes}</div></div>
      </div>
    </div>
  </div>

  );
};

export default ViewIncident;

import React, { useContext } from "react";
import { Box, Icon, Table } from "@amzn/awsui-components-react";
import { EntityAndManagerContext } from "../EntityAndManagerProvider";

const SiteCounts = () => {
    const { siteCountsByRegion } = useContext(EntityAndManagerContext);

    if (siteCountsByRegion[0]?.site_count > 0) {
        return (<Table
            columnDefinitions={[
                {
                    id: "region",
                    header: <Box fontWeight="bold" textAlign="center" variant="div">Region</Box>,
                    cell: item => <Box textAlign="center">{item.region}</Box>,
                },
                {
                    id: "site_count",
                    header: <Box fontWeight="bold" textAlign="center" variant="div" >Site count</Box>,
                    cell: item => <Box textAlign="center">{item.site_count}</Box>,
                },
            ]}
            enableKeyboardNavigation
            items={siteCountsByRegion}
            sortingDisabled
        />)
    }
    return (
        <Box fontSize="display-l" fontWeight="bold" padding={{ top: "l" }} textAlign="center">
            <Icon name="status-in-progress" size="large" />
        </Box>
    )
}

export default SiteCounts;
import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../useModal";
import { IncidentsContext } from "./IncidentsProvider";
import {
  ExpandableSection,
  Table,
  Button,
  SpaceBetween,
  ButtonDropdown,
  Header,
  TextFilter,
  Pagination,
} from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import EmptyState from "../EmptyState";
import { IncidentType, IncidentsGroupedByDate } from "../../types";
import { paginationLabels } from "../../constants";


export type IncidentTableAccordionRowPropType = { headerText: string, incidentGroups: IncidentsGroupedByDate };
export type IncidentGroupingType = {
  date: string,
  theIncidents: IncidentType[]
}

const IncidentTableAccordionRow = ({ headerText, incidentGroups }: IncidentTableAccordionRowPropType) => {
  const incidentGroupingsForTable: IncidentGroupingType[] = [];
  for (const k in incidentGroups) {
    incidentGroupingsForTable.push({
      date: k,
      theIncidents: incidentGroups[k]
    })
  }

  const [
    selectedItems,
    setSelectedItems
  ] = useState<IncidentGroupingType[]>([]);
  const { showModal,} = useContext(ModalContext);
  const { selectedIncidents, setSelectedIncidents } =  useContext(IncidentsContext)

  const { items: incidentItems, collectionProps, filterProps, paginationProps } = useCollection(incidentGroupingsForTable, {
    filtering: {
      empty: (
        <EmptyState
          title="No Incidents Found"
        />
      )
    }
  });

  useEffect(() => {
    if (selectedIncidents[0].id === "") {
      setSelectedItems([]);
    }
  }, [selectedIncidents[0].id]);
  
  return (
    <ExpandableSection variant="container" headerText={headerText}  >
      <Table
        {...collectionProps}
        ariaLabels={{
          selectionGroupLabel: "Items selection",
          allItemsSelectionLabel: ({ selectedItems }) =>
            `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
            } selected`,
        }}
        columnDefinitions={[
          {
            id: "date_reported",
            header: "Date reported",
            cell: (e: IncidentGroupingType) => e.date,
            sortingField: "date_reported",
            isRowHeader: true,
            width: 225,
          },

          {
            id: "count",
            header: "Number of incidents",
            cell: (e: IncidentGroupingType) => e.theIncidents.length,
            sortingField: "location"
          },

          {
            id: "manage",
            header: "",
            cell: (e: IncidentGroupingType) => <Button disabled={selectedIncidents[0].id === ""} onClick={() => {
              showModal('workbench');
            }}>Manage</Button>,
            sortingField: "location"
          },

        ]}
        columnDisplay={[
          { id: "date_reported", visible: true },
          { id: "count", visible: true },
          { id: "manage", visible: true },
        ]}
        enableKeyboardNavigation
        items={incidentItems}
        loadingText="Loading resources"
        selectionType="single"
        wrapLines
        trackBy="date"
        filter={
          <TextFilter
            {...filterProps}
            filteringPlaceholder="Search by location, title, etc."
            filteringAriaLabel="Filter incidents"
          />
        }
        header={
          <Header
            actions={
              <SpaceBetween
                direction="horizontal"
                size="xs"
              >
                <ButtonDropdown
                  onItemClick={(evt) => {
                    if (evt.detail.id === "incident_details") {
                      showModal("incident");
                    } else if (evt.detail.id === "workbench") {
                      showModal("workbench");
                    }
                    setSelectedItems([]);
                  }}
                  items={[
                    {
                      text: "View details",
                      id: "incident_details",
                      disabled: selectedIncidents[0].id === ""
                    },
                    {
                      text: "Edit in workbench",
                      id: "workbench",
                      disabled: selectedIncidents[0].id === ""
                    }
                  ]}
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          ></Header>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        onSelectionChange={({ detail }) => {
          setSelectedItems(detail.selectedItems);
          setSelectedIncidents(detail.selectedItems[0].theIncidents);
        }}
        selectedItems={selectedItems}
      />
    </ExpandableSection>

  );
};

export default IncidentTableAccordionRow;

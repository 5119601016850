import React, { useContext, useState } from "react";
import {Navigate} from "react-router-dom";
import {
  ButtonDropdown,
  SpaceBetween,
  Header,
} from "@amzn/awsui-components-react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Button from "@amzn/awsui-components-react/polaris/button";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import { useCollection } from "@amzn/awsui-collection-hooks";
import EditSecurityManagerModal from "./EditSecurityManagerModal";
import { RegionsAorsCountriesContext } from "./useRegionsAorsCountries"
import { ModalContext } from "../useModal";
import { RoleContext } from "../RoleProvider";
import EmptyState from "../EmptyState";
import { SecurityManagerType } from "../../types";
import { EmptyManager, paginationLabels } from "../../constants";

const AsmsAndRsmsTable = () => {
  const { allManagers, setIsEditing } = useContext(RegionsAorsCountriesContext);
  const { showModal, setSelectedSecurityManager } = useContext(
    ModalContext
  );
  const {roleFetchComplete, userIsRiskAdmin} = useContext(RoleContext);
  const [selectedItems, setSelectedItems] = useState<SecurityManagerType[]>([]);
  function getLoadingState(entities: SecurityManagerType[]): boolean {
    return entities.length <= 1;
  }

  const {
    items: itemsFromUseCollection,
    actions,
    collectionProps,
    paginationProps,
  } = useCollection(allManagers, {
    filtering: {
      empty: <EmptyState title="Choose a country" />,
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: 50 },
    sorting: {},
    selection: {},
  });

  if(!userIsRiskAdmin && roleFetchComplete){
    return <Navigate to="/" />
  }

  if(!roleFetchComplete) {
    return <EmptyState title="Loading corporate security contacts..." subtitle="" action="" />
  }

  return (
    <>
      <Table
        {...collectionProps}
        columnDefinitions={[
          {
            id: "region",
            header: "Region",
            cell: (e: SecurityManagerType) => <span data-testid="region-span">{e.region}</span>,
            isRowHeader: true,
            width: 295,
          },
          {
            id: "aor",
            header: "AOR",
            cell: (e: SecurityManagerType) => <span data-testid="aor-span">{e.aor}</span>,
          },
          {
            id: "full_name",
            header: (
              "Full name"
            ),
            cell: (e: SecurityManagerType) => <span data-testid="full_name-span">{e.full_name}</span>
          },
          {
            id: "alias",
            header: (
              "Alias"
            ),
            cell: (e: SecurityManagerType) => <span data-testid="alias-span">{e.alias}</span>
          },
          {
            id: "role",
            header: "Role",
            cell: (e: SecurityManagerType) => <span data-testid="role-span">{e.role}</span>
          },
          {
            id: "rollup",
            header: "Roll-Up",
            cell: (e: SecurityManagerType) => <span data-testid="roll_up-span">{e.roll_up}</span>
          },
        ]}
        columnDisplay={[
          { id: "region", visible: true },
          { id: "aor", visible: true },
          { id: "full_name", visible: true },
          { id: "alias", visible: true },
          { id: "role", visible: true },
          { id: "rollup", visible: true },
        ]}
        enableKeyboardNavigation
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <ButtonDropdown
                  onItemClick={(e) => {
                    switch (e.detail.id) {
                      case "editSecurityManager":
                        setIsEditing(true);
                        showModal("editSecurityManager")
                        break;
                      case "addSecurityManager":
                        setIsEditing(false);
                        setSelectedSecurityManager(EmptyManager);
                        setSelectedItems([]);
                        showModal("editSecurityManager")
                        break;
                    }
                  }}
                  items={[
                    {
                      text: "Edit",
                      id: "editSecurityManager",
                      disabled: selectedItems.length === 0,
                    },
                    {
                      text: "Add New",
                      id: "addSecurityManager",
                      disabled: false,
                    },
                  ]}
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          ></Header>
        }
        items={itemsFromUseCollection}
        loading={getLoadingState(allManagers)}
        loadingText="Fetching RSMs and ASMs"
        onSelectionChange={({ detail }) => {
          setSelectedItems(detail.selectedItems);
          setSelectedSecurityManager(detail.selectedItems[0])
        }}
        selectedItems={selectedItems}
        selectionType="single"
        wrapLines
        trackBy="id"

        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }

      />
      <EditSecurityManagerModal onSuccessfulSave={() => { setSelectedItems([]) }} />
    </>
  );
};

export default AsmsAndRsmsTable;

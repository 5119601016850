import React, { useContext } from 'react';
import IncidentTableAccordionRow from './IncidentTableAccordionRow';
import { IncidentsContext } from "./IncidentsProvider";

const AggregatedIncidentsAccordion = () => {

    const { rawIncidentsGroupedByDay, 
        needsReviewIncidentsGroupedByDay, 
        bookmarkedIncidentsGroupedByDay,
        duplicateIncidentsGroupedByDay, 
        nonAttributableIncidentsGroupedByMonth,
        triagedIncidentsGroupedByMonth } = useContext(IncidentsContext)
    return (<div>
        <IncidentTableAccordionRow headerText="Raw (uncoded) incidents" incidentGroups={rawIncidentsGroupedByDay} />
        <IncidentTableAccordionRow headerText="Raw (uncoded) incidents pending review" incidentGroups={needsReviewIncidentsGroupedByDay} />
        <IncidentTableAccordionRow headerText="Bookmarked incidents" incidentGroups={bookmarkedIncidentsGroupedByDay} />
        <IncidentTableAccordionRow headerText="Duplicate incidents" incidentGroups={duplicateIncidentsGroupedByDay} />
        <IncidentTableAccordionRow headerText="Incidents not attributable (N/A)" incidentGroups={nonAttributableIncidentsGroupedByMonth} />
        <IncidentTableAccordionRow headerText="Processed (coded) incidents" incidentGroups={triagedIncidentsGroupedByMonth} />
    </div>)
}

export default AggregatedIncidentsAccordion;
import React, { useContext, useState } from "react";
import EntityTable from "./HomePage/EntityTable";
import TriagedIncidentsTable from "./incidents/TriagedIncidentsTable";
import { FlashMessageContext } from "./useFlashMessages";
import { EntityAndManagerContext } from "./EntityAndManagerProvider";
import { ModalContext } from "./useModal";


import {
    Button,
    ColumnLayout,
    Container,
    Header,
} from "@amzn/awsui-components-react/polaris";

const PrimaryView = () => {
    const { resetMessages } = useContext(FlashMessageContext);
    const { setIsEditing } = useContext(EntityAndManagerContext);
    const { showModal, selectedEntity, setSelectedLocation } = useContext(ModalContext);

    return (<Container>
        <Container
            header={
            
                     <ColumnLayout columns={2}>
                     <h2 className="poseiden">ACS global sites</h2>
                     <div style={{float: "right", marginTop: "12px", verticalAlign: "middle"}}><Button onClick={() =>{
                        setIsEditing(false);
                        showModal("addSite");
                     }}>New site</Button></div>
                     </ColumnLayout>
                   
               
            }
        >
            <EntityTable clearMessages={resetMessages} />
        </Container>
        <Container
            header={<Header variant="h2"><span className="poseiden">Security incident database (workbench)</span></Header>} variant="stacked"
        >
            <TriagedIncidentsTable showEditBtn={false} />
        </Container>
    </Container>);
};

export default PrimaryView;
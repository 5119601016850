import React, { useContext, useState } from "react";
import { RiskType, SubRiskType } from "../types";
import { RiskContext } from "./RiskProvider";
import { ModalContext } from "./useModal";
import {
  ExpandableSection,
  Table,
  Button,
  SpaceBetween,
  ButtonDropdown,
  Header,
  Popover,
  Icon,
} from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import EmptyState from "./EmptyState";
import { prettyDateString } from "../utils";

export type RiskRowPropType = { risk: RiskType; subRisks: SubRiskType[] };

const RiskRow = ({ risk, subRisks }: RiskRowPropType) => {
  const { dispatch } = useContext(RiskContext);
  const { showModal } = useContext(ModalContext);
  const { risk_name_full } = risk;

  const {
    items: itemsFromUseCollection,
  } = useCollection(subRisks, {
    filtering: {
      empty: (
        <EmptyState
          title="No Entities Found"
          action={<Button>Create Entity</Button>}
        />
      )
    }
  });
  return (
    <ExpandableSection variant="container" headerText={risk_name_full} onChange={(_e) => {
      dispatch({ Type: "SET_SELECTED_RISK_CATEGORY", Cargo: { riskCategoryId: risk.risk_number } });
    }}>
      <Table
        columnDefinitions={[
          {
            id: "sub_risk_name",
            header: "Sub risk name",
            cell: (item: SubRiskType) => <Popover
              dismissButton={true}
              position="top"
              size="large"
              triggerType="custom"
              content={item.description}
            >
              {item.sub_risk_name} <Icon name="status-info" />
            </Popover>,
            isRowHeader: true,
            width: 225,
          },
          {
            id: "sub_risk_created",
            header: "Created",
            cell: (e: SubRiskType) => prettyDateString(e.edw_created_date),
            width: 225,
          },
          {
            id: "sub_risk_lastmod",
            header: "Last modified",
            cell: (e: SubRiskType) => prettyDateString(e.last_updated),
            width: 225,
          },
          {
            id: "sub_risk_edit",
            header: "Edit",
            cell: (item) => (
              <Button variant="inline-link" ariaLabel="Edit" onClick={(_e) => {
                dispatch({ Type: "SET_SELECTED_SUBRISK", Cargo: { acs_risk_dim_sk: item.acs_risk_dim_sk } })
                showModal("editSubRisk");
              }}>
                Edit
              </Button>
            ),
          },
          {
            id: "sub_risk_delete",
            header: "Delete",
            cell: (item) => (
              <Button variant="inline-link" ariaLabel="Delete">
                Delete
              </Button>
            ),
          },
        ]}
        columnDisplay={[
          { id: "sub_risk_name", visible: true },
          { id: "sub_risk_created", visible: true },
          { id: "sub_risk_lastmod", visible: true },
          { id: "sub_risk_edit", visible: true },
          { id: "sub_risk_delete", visible: false },
        ]}
        items={itemsFromUseCollection}
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size={"s"}>
                <ButtonDropdown
                  onItemClick={(e) => {
                    switch (e.detail.id) {
                      case "add_risk":
                        dispatch({ Type: "SET_SELECTED_RISK_CATEGORY", Cargo: { riskCategoryId: risk.risk_number } });
                        dispatch({ Type: "SET_SELECTED_SUBRISK_TO_EMPTY", Cargo: { parentRiskID: risk.risk_number } });
                        showModal("createSubRisk")
                        break;
                      case "edit_category":
                        dispatch({ Type: "SET_SELECTED_RISK_CATEGORY", Cargo: { riskCategoryId: risk.risk_number } });
                        showModal("editRisk")
                        break;
                    }
                  }}
                  items={[
                    {
                      text: "Add Risk",
                      id: "add_risk",
                      disabled: false,
                    },
                    {
                      text: "Edit Category",
                      id: "edit_category",
                      disabled: false,
                    },
                    {
                      text: "Delete Category",
                      id: "delete_category",
                      disabled: true,
                    },
                  ]}
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          ></Header>
        }
      />
    </ExpandableSection>

  );
};

export default RiskRow;

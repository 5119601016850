import React, { useContext, useEffect, useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Toggle from "@amzn/awsui-components-react/polaris/toggle";
import Button from "@amzn/awsui-components-react/polaris/button";
import { Container, Flashbar, FormField, Input, Select, SelectProps, Textarea } from "@amzn/awsui-components-react";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";

import { ModalContext } from "../../useModal";
import { emptyIncident } from "src/constants";
import { EditIncidentContext } from "./useEditIncident";
import { IncidentsContext } from "../IncidentsProvider";
import FieldsForCompletedIncidents from "./FieldsForCompletedIncidents";

const EditIncidentModal = () => {
  const { isShowing, hideModals } = React.useContext(ModalContext);

  const {
    analystNotesValue,
    setAnalystNotesValue,
    batchMode,
    bookmarkedValue,
    setBookmarkedValue,
    checkForErrors,
    cityValue,
    errors,
    setCityValue,
    countryValue,
    setCountryValue,
    dateReportedValue,
    setDateReportedValue,
    isDuplicateValue,
    setIsDuplicateValue,
    locationValue,
    setLocationValue,
    needsReviewLocal,
    setNeedsReviewLocal,
    setShowErrors,
    notesValue,
    setNotesValue,
    regionValue,
    setRegionValue,
    selectedIncidentIndex,
    decrementSelectedIndex,
    incrementSelectedIndex,
    setSelectedIncidentIndex,
    setErrors,
    showErrors,
    stateProvinceValue,
    setStateProvinceValue,
   
    titleValue,
    setTitleValue,
    triageStatus,
    setTriageStatus,
    typeValue,
    setTypeValue,
    updateIncident
  } = useContext(EditIncidentContext);

  const { setNeedsRefetch, selectedIncidents, setSelectedIncidents } = useContext(IncidentsContext);
  let thisIncident = selectedIncidents[selectedIncidentIndex] || emptyIncident;

  const [saveButtonText, setSaveButtonText] = useState("Save")
  const [cancelButtonText, setCancelButtonText] = useState("Cancel")

  useEffect(() => {
    setSaveButtonText(batchMode && selectedIncidentIndex < selectedIncidents.length - 1 ? "Save & next" : "Save");
  }, [batchMode, selectedIncidentIndex]);

  return (
    <Modal
      footer={
        <Container>
          {batchMode && <Box float="left">{selectedIncidentIndex + 1} of {selectedIncidents.length}</Box>}
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              {batchMode && <Button disabled={selectedIncidentIndex === 0} variant="link" onClick={(_event) => {
                decrementSelectedIndex()
                setErrors([""]);
                setShowErrors(false);
              }}>Prev</Button>}
              <Button variant="link" onClick={(_event) => {
                if (batchMode) {
                  setNeedsRefetch();
                }
                hideModals();
                setErrors([""]);
                setShowErrors(false);
                setSelectedIncidents([emptyIncident]);
                setSelectedIncidentIndex(0);
              }}>{cancelButtonText}</Button>
              <Button variant="link" onClick={(_event) => {
                if (!checkForErrors()) {
                  updateIncident();
                  if (batchMode) {
                    if (selectedIncidentIndex < selectedIncidents.length - 1) {
                      incrementSelectedIndex();
                    } else {
                      hideModals();
                      setSelectedIncidentIndex(0);
                    
                    }
                    setCancelButtonText("Close");
                  } else {
                    hideModals();
                    setSelectedIncidentIndex(0);
                  }

                  setErrors([""]);
                  setShowErrors(false);
                }
              }}>{saveButtonText}</Button>
              {batchMode && <Button disabled={selectedIncidentIndex === selectedIncidents.length - 1} variant="link" onClick={(_event) => {
                incrementSelectedIndex()
                setErrors([""]);
                setShowErrors(false);
              }}>Next</Button>}
            </SpaceBetween>
          </Box>
        </Container>
      }
      header={selectedIncidents[selectedIncidentIndex]?.display_id || thisIncident.id}
      onDismiss={() => hideModals()}
      size="max"
      visible={isShowing("workbench")}
    >
      <Container>
        {showErrors && <Flashbar
          items={[
            {
              content: <ul>{errors.map((e, index) => (<li key={`error-${index}`}>{e}</li>))}</ul>,
              dismissible: true,
              id: "message_1",
              onDismiss: () => { },
              type: "warning",
            }
          ]}
        />}
        <div className="modal-body">
          <div className="edit-incident">
            <div className="left-col">
              <div className="admin-section">
                <FormField label="Title">
                  <Input
                    value={titleValue}
                    onChange={event =>
                      setTitleValue(event.detail.value)
                    }
                  />
                </FormField>
                <FormField label="Date reported">
                  <Input
                    value={dateReportedValue}
                    disabled
                    onChange={event =>
                      setDateReportedValue(event.detail.value)
                    }
                  />
                </FormField>
                <FormField label="Region">
                  <Input
                    value={regionValue}
                    onChange={event =>
                      setRegionValue(event.detail.value)
                    }
                  />
                </FormField>
                <FormField label="Country">
                  <Input
                    value={countryValue}
                    onChange={event =>
                      setCountryValue(event.detail.value)
                    }
                  />
                </FormField>
                <FormField label="State/Province">
                  <Input
                    value={stateProvinceValue}
                    onChange={event =>
                      setStateProvinceValue(event.detail.value)
                    }
                  />
                </FormField>
                <FormField label="City">
                  <Input
                    value={cityValue}
                    onChange={event =>
                      setCityValue(event.detail.value)
                    }
                  />
                </FormField>

                <FormField label="Location">
                  <Input
                    value={locationValue}
                    onChange={event =>
                      setLocationValue(event.detail.value)
                    }
                  />
                </FormField>
              </div>
              <FormField label="Type">
                <Input
                  value={typeValue}
                  onChange={event =>
                    setTypeValue(event.detail.value)
                  }
                />
              </FormField>
              <div className="space-above">
                <label>Notes</label>
                <div className="space-above">
                  <Textarea
                    readOnly
                    rows={15}
                    onChange={({ detail }) => setNotesValue(detail.value)}
                    value={notesValue}
                  />
                </div>
              </div>
              <div className="space-above">
                <label>Analyst notes</label>
                <div className="space-above">
                  <Textarea
                    rows={5}
                    onChange={({ detail }) => setAnalystNotesValue(detail.value)}
                    value={analystNotesValue}
                  />
                </div>
              </div>
              <div className="space-above">
                <ColumnLayout columns={2}>
                  <Toggle
                    disabled={isDuplicateValue === 1}
                    onChange={({ detail }) => {
                      setTriageStatus(detail.checked ? "complete" : "n/a");
                      if (detail.checked) {
                        setIsDuplicateValue(0);
                      }
                    }}
                    checked={triageStatus === "complete"}
                  >
                    Attributable
                  </Toggle>
                  <SpaceBetween direction="vertical" size="s">
                    <Toggle
                      onChange={({ detail }) =>
                        setNeedsReviewLocal(detail.checked ? 1 : 0)
                      }
                      checked={needsReviewLocal === 1}
                    >
                      Needs review
                    </Toggle>

                    <Toggle
                      checked={isDuplicateValue === 1}
                      disabled={triageStatus === "complete"}
                      onChange={({ detail }) =>
                        setIsDuplicateValue(detail.checked ? 1 : 0)
                      }
                    >
                      Is duplicate
                    </Toggle>

                    <Toggle
                      onChange={({ detail }) =>
                        setBookmarkedValue(detail.checked ? 1 : 0)
                      }
                      checked={bookmarkedValue === 1}
                    >
                      Bookmark
                    </Toggle>
                  </SpaceBetween>
                </ColumnLayout>
              </div>
            </div>
            <FieldsForCompletedIncidents />
          </div>
        </div>
      </Container>
    </Modal >
  );
}

export default EditIncidentModal;